import { useCallback, MouseEvent as ReactMouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { YouHaveReachedThePlanModal } from '../../../modals/you-have-reached-the-plan';
import { IModalProps } from '../../../modals/you-have-reached-the-plan/types';
import { useTrialEnded } from '../../../shared/hooks/use-trial-ended';
import { LogoWrapper } from './styles';
import { Typography } from 'shared/ui/atoms/typography';
import { Spacing } from 'shared/ui/atoms/spacing';
import { RouteE } from 'shared/config/navigation';
import { NavigationLink } from './navigation-link';
import ContactUs from './contact-us';

export const Sidebar = () => {
    const trialEnded = useTrialEnded();

    const strictClick = useCallback((event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
        return (type: IModalProps['type']) => {
            if (trialEnded) {
                event.preventDefault();

                YouHaveReachedThePlanModal.open({
                    type
                });
            }
        };
    }, [trialEnded]);

    return (
        <>
            <LogoWrapper>
                <Link to='/'>
                    <img alt='logo' src='/images/logo.svg' />
                </Link>
            </LogoWrapper>
            <Spacing ml='26px' mr='26px' mb='20px'>
                <NavigationLink
                    icon='calendar'
                    href={RouteE.HOME}
                    label='Event Central'
                />
                <NavigationLink
                    icon='guests'
                    href={RouteE.GUEST_MANAGEMENT}
                    label='Guest Central'
                    onClick={(event) => strictClick(event)('trial')}
                />
                <NavigationLink
                    icon='cells'
                    href={RouteE.STAKEHOLDER_MANAGEMENT}
                    label='Stakeholder Central'
                    onClick={(event) => strictClick(event)('trial')}
                />
                <NavigationLink
                    icon='drawer'
                    href={RouteE.VAULT}
                    label='Vault'
                    onClick={(event) => strictClick(event)('trial')}
                />
            </Spacing>
            <Spacing ml='26px' mb='20px'>
                <Typography size='labels' color='grey2' uppercase bold>
                    My account
                </Typography>
            </Spacing>
            <Spacing ml='26px' mr='26px' mb='20px'>
                <NavigationLink
                    icon='user-circle'
                    href={RouteE.PROFILE_MAIN}
                    label='Profile'
                />
                <NavigationLink
                    icon='wallet'
                    href={RouteE.PAYMENTS}
                    label='Subscriptions'
                />
            </Spacing>
            <Spacing ml='26px' mb='20px'>
                <Typography size='labels' color='grey2' uppercase bold>
                    Support
                </Typography>
            </Spacing>
            <Spacing ml='26px' mr='26px' mb='20px'>
                <NavigationLink
                    icon='faq'
                    href={RouteE.FAQ}
                    label='FAQs'
                />
                <NavigationLink
                    icon='globe'
                    href={RouteE.HOW_TO}
                    label='How To'
                />
                {/*<NavigationLink*/}
                {/*    icon='video'*/}
                {/*    href={RouteE.VIDEO_TUTORIALS}*/}
                {/*    label='Video Tutorials'*/}
                {/*/>*/}
                <ContactUs />
            </Spacing>
        </>
    );
};
