import { Link } from 'react-router-dom';
import { RouteE } from 'shared/config/navigation';
import { useUserInfoQuery } from 'entities/user/model/user';
import { UserAvatar } from 'entities/user/ui/user-avatar';
import { useScreenDetect } from 'shared/hooks/use-screen-detect';
import { Typography } from 'shared/ui/atoms/typography';

export const UserProfile = () => {
    const { data } = useUserInfoQuery();
    const { isMobile } = useScreenDetect();

    return (
        <Link 
            to={RouteE.PROFILE} 
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}
        >
            <UserAvatar user={data} size={isMobile ? 30 : 50} />
            <Typography size='p_small_bold' color='linksIcons' style={{ marginTop: 10 }}>{data?.name}</Typography>
        </Link>
    );
};
