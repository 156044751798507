import Popup from 'reactjs-popup';
import styled, { css } from 'styled-components';

export const StyledPopup = styled(Popup)`
    &-content {
        padding: 8px;
        border: none;
        z-index: 9999999999999 !important;
        border-radius: 16px !important;
        box-shadow: 0 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1) !important;
        backdrop-filter: blur(16px) !important;
        font-family: 'DM Sans';
        font-size: 13px;
        font-weight: 500;
        color: #8083A3;

        span {
            display: block;
            text-align: center;
        }

        ${({ theme }) => css`
            background-color: ${theme.colors.white};
        `}
    }

    &-arrow {
        filter: none;
        stroke: none;
        box-shadow: 0px 40px 64px -12px rgba(0, 0, 0, 0.08), 0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1) !important;
    }
`;

