import { dropdownShadow } from 'shared/mixins/shadows';
import styled, { css } from 'styled-components';
import { media } from '../../../mixins/media';

export const StyledContent = styled.div`
    background-color: white;
    border-radius: 16px;
    border: none;
    backdrop-filter: blur(16px);
    max-height: 100vh;  
    overflow-y: auto;
    
    ${media.md(css`
        max-height: unset;
        overflow-y: unset;
    `)}

    ${dropdownShadow}
`;
