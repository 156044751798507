import { media } from 'shared/mixins/media';
import { SearchInput } from 'shared/ui/organisms/search-input';
import styled from 'styled-components';

export const StyledSearchInput = styled(SearchInput)`
    ${media.md`
        max-width: 200px;
    `}
    input {
        background-color: #fff;
        border-bottom: 1px solid #e8e8e8;
        padding-left: 30px;
        padding-bottom: 2px;
        padding-top: 13px;
        border-radius: 0;
        font-size: 12px;
        font-weight: 700;

        &::placeholder {
            font-weight: 700;
            font-size: 12px;
            color: #8083a3;
        }
    }

    svg {
        width: 15px;
        height: 15px;
    }

    & svg:first-child {
        left: 8px;
        top: 18px;
    }
`;