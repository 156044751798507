import styled from 'styled-components';

export const ContactUsStyled = styled.div`
    display: flex;
    padding: 12px 15px;
    background: 'transparent';
    mix-blend-mode: multiply;
    border-radius: 11px;
    &:hover {
      cursor: pointer;  
    }
    & + & {
        margin-top: 7px;
    }

    & a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }

    & svg {
        margin-right: 16px;
    }
`; 
