import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { EventApi } from 'shared/api/event';
import { ICreateEventRequest } from 'shared/api/event/event.types';

export const useEventQuery = (eventId: number) => useQuery({
    queryKey: ['events/single', eventId],
    queryFn: () => EventApi.fetchEventById(eventId).then(d => d.data.event)
});

export const useEventsQuery = () => useQuery({
    queryKey: ['events/all'],
    queryFn: () => EventApi.fetchAvailableEvents().then(d => d.data)
});

export const useMyEventsQuery = () => useQuery({
    queryKey: ['events/my'],
    queryFn: () => EventApi.fetchAllMyEvents().then(d => d.data)
});


export const useVaultEventsQuery = () => useInfiniteQuery({
    queryKey: ['events/vault'],
    queryFn: () => EventApi.fetchVaultEvents().then((response) => response.data),
    getNextPageParam: (last) => last.currentPage === last.lastPage ? undefined : last.currentPage + 1
});

export const useEventCategoriesQuery = () => useQuery({
    queryKey: ['events/categories'],
    queryFn: () => EventApi.getCategories().then((response) => response.data)
});

export const useEventStakeholdersQuery = (eventId: number) => useQuery({
    queryKey: ['event/stakeholders', eventId],
    queryFn: () => EventApi.getStakeholders(eventId).then((response) => response)
});

export const useLinkedEventsQuery = (eventId: number) => useQuery({
    queryKey: ['events/linked', eventId],
    queryFn: () => EventApi.getLinkedEvents(eventId).then((response) => response.data)
});

export const useCreateMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['events/create'],
        mutationFn: (data: ICreateEventRequest) => EventApi.create(data)
            .then((response) => response.data),
        onSuccess: () =>
            queryClient.refetchQueries({ queryKey: ['events/all'] })
    });
};

export const useUpdateMutation = (eventId: number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['events/create'],
        mutationFn: (data: ICreateEventRequest) => EventApi.update(eventId, data)
            .then((response) => response.data),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['events/all'] });
            queryClient.refetchQueries({ queryKey: ['events/single', eventId] });
        }
    });
};

export const useUpdateVaultStateMutation = (id: number) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['events/vault', id],
        mutationFn: (state: boolean) => EventApi.updateVaultState(id, { isVault: state }),
        onSuccess: () => {
            queryClient.refetchQueries({ queryKey: ['events/all'] });
            queryClient.refetchQueries({ queryKey: ['events/vault'] });
            queryClient.invalidateQueries({ queryKey: ['events/single', id] });
        }
    });
};

export const useDeleteMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['events/delete'],
        mutationFn: (id: number) => EventApi.delete(id),
        onSuccess: () =>{
            queryClient.refetchQueries({ queryKey: ['events/all'] });
            queryClient.refetchQueries({ queryKey: ['events/vault'] });
        }
    });
};

export const useLinkEventMutation = () => {
    const client = useQueryClient();

    return useMutation({
        mutationKey: ['events/link-event'],
        mutationFn: ({
            id,
            linkEventId
        }: { id: number, linkEventId: number }) =>
            EventApi.linkEvent(id, linkEventId).then((response) => response.data),
        onSuccess: () => client.invalidateQueries({ queryKey: ['events/linked'] })
    });
};

export const useUnlinkEventMutation = () => {
    const client = useQueryClient();
    
    return useMutation({
        mutationKey: ['events/unlink-event'],
        mutationFn: ({
            id,
            linkEventId
        }: { id: number, linkEventId: number }) =>
            EventApi.unlinkEvent(id, linkEventId).then((response) => response.data),
        onSuccess: () => client.invalidateQueries({ queryKey: ['events/linked'] })
    });
};
