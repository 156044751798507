import { IEvent } from 'shared/api/event/event.types';
import type { FC, MouseEvent as ReactMouseEvent } from 'react';
import { PostEventNotesModal } from '../../../../modals/post-event-notes';
import { useTrialEnded } from '../../../../shared/hooks/use-trial-ended';
import {  StyledBadge, StyledTextWrapper, StyledUserList, StyledWrapper } from './styles';
import { Typography } from 'shared/ui/atoms/typography';
import { formatDateFromBe } from 'shared/lib/dates';
import { Spacing } from 'shared/ui/atoms/spacing';
import { generatePath, Link } from 'react-router-dom';
import { RouteE } from 'shared/config/navigation';
import { useTimeLeft } from '../../hooks/use-time-left';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import { useUserInfoQuery } from '../../../user/model/user';
import { ThreeDotsActionsMenu } from 'shared/ui/organisms/three-dots-actions-menu';
import { useCallback, useMemo } from 'react';
import { ConfirmModal } from '../../../../modals/confirm-modal';
import { useDeleteMutation, useUpdateVaultStateMutation } from '../../model/event';
import { EventApi } from '../../../../shared/api/event';
import { YouHaveReachedThePlanModal } from '../../../../modals/you-have-reached-the-plan';
import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { preventDefault } from 'shared/lib/dom';

interface IProps {
    data: IEvent;
    parentWidth?: number;
}

export const EventCard: FC<IProps> = ({ data, parentWidth }) => {
    const trialEnded = useTrialEnded();
    const { d: daysLeft } = useTimeLeft(data.startedAt);
    const { data: user } = useUserInfoQuery();
    const { mutate } = useDeleteMutation();
    const { mutate: updateEventVaultState } = useUpdateVaultStateMutation(
        data.id
    );

    const isOwner = data.ownerId === user?.id;
    const daysBeforeEvent = data?.startedAt ? daysLeft : -1;
    const showBadge = daysBeforeEvent >= 0 && daysBeforeEvent <= 30;
    const widthLg = (parentWidth ?? 0) / 3 - 50 - 36;
    const widthSm = (parentWidth ?? 0) / 2 - 50 - 36;
    const widthXs = (parentWidth ?? 0) - 36 - 42;
    
    const actions = useMemo(
        () => [
            {
                label: data.isVault ? 'Move To Event Central' : 'Move To Vault',
                onClick: () => {
                    if (!data.isVault) {
                        EventApi.fetchVaultEvents()
                            .then((r) => r.data.total)
                            .then((total) => {
                                if (
                                    user!.subscriptionPermissions.create.vault >
                                    total
                                ) {
                                    ConfirmModal.open({
                                        title: 'Are you sure you want to move this event to your Vault?',
                                        onSubmit: () =>
                                            updateEventVaultState(
                                                !data.isVault
                                            ),
                                        text: 'You will still be able to see all of your information but it will no longer be editable.',
                                        confirmButtonText: 'Confirm'
                                    });
                                } else {
                                    YouHaveReachedThePlanModal.open({
                                        type: 'vault'
                                    });
                                }
                            });
                        return;
                    }
                    EventApi.fetchAllMyEvents()
                        .then((d) => d.data.events.total)
                        .then((total) => {
                            if (
                                user!.subscriptionPermissions.create.events >
                                total
                            ) {
                                ConfirmModal.open({
                                    title: 'Are you sure you want to move this event to your Event Central?',
                                    onSubmit: () =>
                                        updateEventVaultState(!data.isVault),
                                    confirmButtonText: 'Confirm'
                                });
                            } else {
                                YouHaveReachedThePlanModal.open({
                                    type: 'events'
                                });
                            }
                        });
                }
            },
            {
                label: 'Delete',
                color: 'errors',
                onClick: () => {
                    ConfirmModal.open({
                        title: 'Are you sure you want to delete?',
                        text: 'Deleting an event will remove all information associated with that event. If you want to archive the event for later access, move it to your Vault instead.',
                        onSubmit: () => mutate(data.id),
                        confirmButtonText: 'Delete'
                    });
                }
            }
        ],
        [data.id, data.isVault, mutate, updateEventVaultState, user]
    );

    const strictClick = useCallback(
        (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => {
            if (trialEnded) {
                event.preventDefault();

                YouHaveReachedThePlanModal.open({
                    type: 'trial'
                });
            }
        },
        [trialEnded]
    );

    const postNotesClick = useCallback(
        (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
            event.preventDefault();

            PostEventNotesModal.open({
                preventEasyClose: true,
                eventId: data.id
            });
        },
        [data.id]
    );

    return (
        <Link
            to={generatePath(RouteE.EVENT_DETAILS, { id: data.id })}
            onClick={strictClick}
        >
            <StyledWrapper isUpcoming={showBadge}>
                <Spacing pb='8px' style={{ borderBottom: '1px solid #E4E6E8' }}>
                    <FlexBox
                        justifyContent={
                            !showBadge ? 'flex-end' : 'space-between'
                        }
                        alignItems='center'
                        style={{ marginBottom: '5px' }}
                    >
                        {showBadge && (
                            <StyledBadge
                                bgColor='mainNeutral'
                                color='linksIcons'
                            >
                                Upcoming - in {daysBeforeEvent} days
                            </StyledBadge>
                        )}
                        <FlexBox columnGap='10px' alignItems='center'>
                            <SvgIcon
                                variant='menu'
                                icon='icon-checkmark'
                                color='buttons'
                            />
                            <Typography size='h_6' color='buttons' bold>
                                {data?.attendedGuestsAttendanceCount ?? 0}{' '}
                                Attending
                            </Typography>
                        </FlexBox>
                    </FlexBox>
                    <FlexBox>
                        <StyledTextWrapper
                            widthXs={widthXs}
                            widthSm={widthSm}
                            widthLg={widthLg}
                        >
                            <Typography
                                style={{ flex: 1 }}
                                color='black1'
                                size='h_4'
                                bold
                            >
                                {data.name}
                            </Typography>
                        </StyledTextWrapper>
                    </FlexBox>
                    <FlexBox justifyContent='space-between' alignItems='center'>
                        <Typography color='grey1' size='p_small'>
                            Start Date:{' '}
                            {data.startedAt
                                ? formatDateFromBe(data.startedAt)
                                : 'TBD'}
                        </Typography>
                        <StyledUserList
                            list={data.stakeholdersUsers ?? []}
                            size={30}
                        />
                    </FlexBox>
                </Spacing>
                <Spacing pt='12px'>
                    <FlexBox alignItems='center' justifyContent='space-between'>
                        <FlexBox
                            columnGap='8px'
                            alignItems='center'
                            onClick={postNotesClick}
                        >
                            <SvgIcon
                                variant='menu'
                                icon='notification-text'
                                width={24}
                                height={24}
                                color={showBadge ? 'uiElements' : 'grey2'}
                            />
                            <Typography
                                size='p'
                                color={showBadge ? 'uiElements' : 'grey2'}
                                bold
                            >
                                Post-Event Notes
                            </Typography>
                        </FlexBox>
                        {isOwner && (
                            <div onClick={preventDefault}>
                                <ThreeDotsActionsMenu
                                    actions={actions}
                                    color={showBadge ? 'uiElements' : 'grey2'}
                                />
                            </div>
                        )}
                    </FlexBox>
                </Spacing>
            </StyledWrapper>
        </Link>
    );
};
