import { StyledWrapper } from './styles';
import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { useCallback } from 'react';

export const AddStakeholderPlus = () => {
    const handleClick = useCallback(() => {
        // TODO
    }, []);

    return (
        <StyledWrapper onClick={handleClick}>
            <SvgIcon icon='plus' width={15} />
        </StyledWrapper>
    );
};
