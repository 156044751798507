import { FC } from 'react';
import { VideoTutorialItem } from 'shared/api/video-tutorial';
import { Video } from 'shared/ui/atoms/video';
import { Spacing } from 'shared/ui/atoms/spacing';
import { Typography } from 'shared/ui/atoms/typography';
import { pluralize } from 'shared/lib/pluralize';
import { StyledWrapper } from './styles';

export const VideoTutorialCard: FC<{ data: VideoTutorialItem }> = ({ data }) => {
    return (
        <StyledWrapper>
            <Video>
                <iframe
                    title={data.name}
                    src={data.youtubeLink}
                    frameBorder='0'
                    allow='accelerometer; autoplay; encrypted-media; gyroscope;'
                    allowFullScreen
                >
                </iframe>
            </Video>
            <Spacing mt='15px' mb='15px'>
                <Typography size='h_2' medium>
                    {data.name}
                </Typography>
                {data.duration && (
                    <Typography color='black2' medium>
                        {pluralize(+data.duration, 'min')}
                    </Typography>
                )}
            </Spacing>
            {data.text && (
                <Typography color='black2' medium>
                    {data.text}
                </Typography>
            )}
        </StyledWrapper>
    );
};
