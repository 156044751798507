/* eslint-disable @typescript-eslint/no-explicit-any */
import { breakpoints, TBreakpoint } from 'shared/ui/config/breakpoints';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export const media = Object.keys(breakpoints).reduce(
    (accumulator, label) => {
        accumulator[label as TBreakpoint] = (...args) => {
            const myTuple: [TemplateStringsArray] = args as any;

            return css`
              @media (min-width: ${breakpoints[label as TBreakpoint]} ) {
                ${css(...myTuple)};
              }
            `;
        };

        return accumulator;
    },
    {} as Record<TBreakpoint, (...args: any) => FlattenSimpleInterpolation>
);
