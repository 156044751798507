import { media } from 'shared/mixins/media';
import styled, { css } from 'styled-components';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import { Spacing } from 'shared/ui/atoms/spacing';

export const StyledLogo = styled.div`
    margin: 40px auto;
    
    & img {
        max-width: 150px;
        width: 100%;
        height: auto;
    }
    
    ${media.md(css`
        margin: 40px 70px;
        
        & img {
            max-width: 250px;
        }
    `)}
`;

export const StyledAuthLayout = styled(FlexBox)`
    height: 100%;
    flex-direction: column;
    background: linear-gradient(0deg, rgba(255, 243, 230, 0.6), rgba(255, 243, 230, 0.6)), #FFFFFF;
    max-height: 100%;
    overflow-y: auto;
    padding-bottom: 200px;
    
    ${media.md(css`
        flex-direction: row;
        max-height: unset;
        overflow-y: unset;
        background: url('/images/sign-bg.png') no-repeat center,
        linear-gradient(0deg, rgba(255, 243, 230, 0.6), rgba(255, 243, 230, 0.6)), #FFFFFF;
        background-size: cover;
    `)};
`;

export const StyledAuthLayoutContent = styled(Spacing)<{ placeMiddle?: boolean }>`
    flex: 1;
    position: relative;
    height: min-content;

    ${media.md`
        position: absolute;
        width: 550px;
        margin: auto;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        max-height: 100%;
        overflow-y: auto;
    `}

    ${({ placeMiddle }) => placeMiddle && css`
        ${media.md`
            bottom: unset;
            top: 160px;
        `}
    `}

    ${({ theme }) => css`
        a {
            text-decoration: none;
            color: ${theme.colors.linksIcons} !important;
        }

        button {
            border-color: ${theme.colors.uiElements};
        }
    `}

`;
