import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { StyledImage, StyledRatio, StyledStub, StyledWrapper } from './lazy-load-image.styles';
import { IProps } from './lazy-load-image.types';
import { useIsMounted } from '../../../hooks/use-is-mounted';

export const LazyLoadImage: FC<PropsWithChildren<IProps>> = ({
    src,
    width,
    height,
    alt,
    title,
    className,
    ratio,
    children
}) => {
    const inView = useInView();
    const isMounted = useIsMounted();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded && inView && src) {
            const img = new Image();
            img.src = src;
            img.onload = () => isMounted(() => setLoaded(true));
        }
    }, [inView, isMounted, loaded, src]);

    return (
        <StyledWrapper title={title} style={{ width, height }} className={className}>
            <StyledRatio ratio={ratio} />
            {inView && loaded && (
                <StyledImage
                    src={src}
                    alt={alt}
                    width={width}
                    height={height}
                />
            )}
            {inView && !loaded && (
                <StyledStub>
                    <img src='/image-stub.svg' />
                </StyledStub>
            )}
            {children}
        </StyledWrapper>
    );
};
