import { FC, ReactNode } from 'react';
import { PopupPosition } from 'reactjs-popup/dist/types';
import { StyledPopup } from './styles';

interface ITooltipProperties {
    children: ReactNode;
    trigger: ReactNode;
    position?: PopupPosition;
    arrow?: boolean;
    disabled?: boolean;
}

export const Tooltip: FC<ITooltipProperties> = ({ disabled, children, trigger, position, arrow }) => {
    if (disabled) {
        return <>{trigger}</>;
    }
    return (
        <StyledPopup
            trigger={() => (
                <span>
                    {trigger}
                </span>
            )}
            position={position || 'bottom center'}
            on={['click', 'hover']}
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            arrow={arrow}
            keepTooltipInside
            closeOnDocumentClick
        >
            {children}
        </StyledPopup>
    );
};
