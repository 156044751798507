import { Typography } from 'shared/ui/atoms/typography';
import { Spacing } from 'shared/ui/atoms/spacing';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import { CreateEventButton } from 'features/event/create-event-button';
import { useScreenDetect } from '../../../../shared/hooks/use-screen-detect';

export const EventsPageHeader = () => {
    const { isMobile } = useScreenDetect();

    if (isMobile) {
        return (
            <Typography color='linksIcons' size='h_5' bold uppercase>
                Event Central
            </Typography>
        );
    }

    return (
        <FlexBox
            alignItemsMd='center'
            flexDirection='column'
            flexDirectionMd='row'
        >
            <Typography color='buttons' size='h_1' uppercase>
                Event Central
            </Typography>
            <Spacing mlMd='25px' mt='15px' mtMd='0'>
                <CreateEventButton />
            </Spacing>
        </FlexBox>
    );
};
