import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withProviders } from 'app/providers';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const RootLayout = () => {
    return (
        <>
            <ReactQueryDevtools initialIsOpen={false} />
            <Outlet />
            <ToastContainer />
        </>
    );
};

export default withProviders(RootLayout);
