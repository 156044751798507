import { FC, forwardRef } from 'react';
import { SvgIcon } from '../svg-icon';
import { StyledButton } from './button.styles';
import { TButton } from './buttons.types';

export const Button: FC<TButton> = forwardRef(
    (
        {
            children,
            icon,
            type = 'button',
            variant = 'primary',
            size = 'regular',
            fullWidth,
            ...rest
        },
        ref
    ) => (
        <StyledButton
            ref={ref}
            fullWidth={fullWidth}
            variant={variant}
            size={size}
            type={type}
            {...rest}
        >
            {icon && (
                <SvgIcon
                    icon={icon}
                    color={variant === 'primary' ? 'white' : 'black2'}
                />
            )}
            {children}
        </StyledButton>
    )
);

Button.displayName = 'Button';
