import { TTypographySize } from './typography.types';

type TTypographyTuple = {
    [key in TTypographySize]: string
};

export const FONT_SIZE: TTypographyTuple = {
    'h_1': '33px',
    'h_2': '20px',
    'h_3': '17px',
    'h_4': '16px',
    'h_5': '15px',
    'h_6': '13px',
    'p': '14px',
    'p_small': '13px',
    'p_small_bold': '13px',
    'labels': '12px'
};

export const FONT_LINE_HEIGHT: TTypographyTuple = {
    'h_1': '35px',
    'h_2': '32px',
    'h_3': '29px',
    'h_4': '24px',
    'h_5': 'auto',
    'h_6': '30px',
    'p': '24px',
    'p_small': '21px',
    'p_small_bold': '13px',
    'labels': '16px'
};