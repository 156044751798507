import { useEffect, useState } from 'react';
import { useSetAppHeader } from 'widgets/header/model/store';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import { Typography } from 'shared/ui/atoms/typography';
import { ContentBox } from 'shared/ui/atoms/content-box';
import { Grid } from 'shared/ui/atoms/grid';
import { VideoTutorialCard } from 'entities/video-tutorials/ui/video-tutorial-card';
import { SectionWhiteBox } from 'shared/ui/atoms/section-white-box';
import { Spacing } from 'shared/ui/atoms/spacing';
import { useVideoTutorialsQuery } from 'entities/video-tutorials/model/video-tutorial';
import { useScreenDetect } from 'shared/hooks/use-screen-detect';
import { StyledIcon, StyledNavItem } from './styles';
import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { IIcon } from 'shared/ui/atoms/svg-icon/svg-icon.types';

const VideoTutorials = () => {
    const { data } = useVideoTutorialsQuery();
    const { isMobile } = useScreenDetect();
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);

    const items = data?.find((item) => item.id === selectedCategory)?.items ?? [];

    useEffect(() => {
        if (data?.length) {
            setSelectedCategory(data[0].id);
        }
    }, [data]);

    useSetAppHeader(
        isMobile ? (
            <Typography size='h_5' color='linksIcons' bold uppercase>Video tutorials</Typography>
        ) : (
            <FlexBox alignItems='center'>
                <Typography color='buttons' size='h_1' uppercase>
                    Video tutorials
                </Typography>
            </FlexBox>
        )
    );

    const content = (
        <FlexBox flexDirection={isMobile ? 'column-reverse' : 'row'}>
            <Grid
                xs={1}
                md={2}
                xl={3}
                columnGap={25}
                rowGap={25}
            >
                {items.map((item) => (
                    <VideoTutorialCard key={item.id} data={item} />
                ))}
            </Grid>
            <Spacing mlMd='25px' mb='25px' mbMd='0' style={!isMobile ? { flex: '0 0 260px' } : {}}>
                <Spacing ml='20px' mlMd='0' mb='0' mbMd='16px'>
                    <Typography size='h_5' bold>
                        Choose a Topic
                    </Typography>
                </Spacing>
                <SectionWhiteBox>
                    <Grid rowGap={18}>
                        {data?.map((item) => (
                            <StyledNavItem
                                alignItems='center'
                                active={selectedCategory === item.id}
                                key={item.id}
                                onClick={() => setSelectedCategory(item.id)}
                            >
                                <StyledIcon alignItems='center' justifyContent='center'>
                                    <SvgIcon
                                        icon={item.icon as IIcon}
                                        variant='video-tutorials'
                                        color={selectedCategory === item.id ? 'white' : 'mainNavLinks'}
                                        width={16}
                                    />
                                </StyledIcon>
                                <Typography
                                    size='h_5'
                                    color={selectedCategory === item.id ? 'mainNavLinks' : 'black2'}
                                    bold
                                >
                                    {item.name}
                                </Typography>
                            </StyledNavItem>
                        ))}
                    </Grid>
                </SectionWhiteBox>
            </Spacing>
        </FlexBox>
    );

    return isMobile ? content : (
        <ContentBox>
            {content}
        </ContentBox>
    );
};

export default VideoTutorials;
