import { createContext } from 'react';
import { OpenedModals } from '../modal.types';

interface ModalContextValue {
    openedModals: OpenedModals;
}

export const ModalContext = createContext<ModalContextValue>({
    openedModals: {} as OpenedModals
});
