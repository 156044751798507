import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useUserInfoQuery } from '../../entities/user/model/user';

export const useTrialEnded = () => {
    const { data: user } = useUserInfoQuery();

    return useMemo(() => {
        const trialEndDate = dayjs(user?.createdAt).add(14, 'day');
        const trialEnded = dayjs().isAfter(trialEndDate);

        return !user?.isTrialPeriod && trialEnded && !user?.activeSubscription;
    }, [user?.activeSubscription, user?.createdAt, user?.isTrialPeriod]);
};
