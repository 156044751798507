import { IUser } from 'shared/api/auth/auth.types';

export const getUserName = (data: IUser): string => {
    let res = '';
    if (data.name) {
        res += data.name;
    }
    if (data.lastName) {
        res += res ? ` ${data.lastName}` : data.lastName;
    }
    if (data.phoneNumber && !res) {
        return data.phoneNumber;
    }
    return res;
};
