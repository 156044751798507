import styled from 'styled-components';
import { media } from 'shared/mixins/media';
import { IStyledWrapper } from './types';

export const StyledWrapper = styled.div<IStyledWrapper>`
  display: ${({ inline }) => inline ? 'inline-flex' : 'flex'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  align-items: ${({ alignItems }) => alignItems || 'stretch'};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  flex-basis: ${({ flexBasis }) => flexBasis || 'auto'};
  flex-shrink: ${({ flexShrink }) => flexShrink || 1};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  flex: ${({ flex }) => flex || '0 1 auto'};
  column-gap: ${({ columnGap }) => columnGap || 'normal'};
  row-gap: ${({ rowGap }) => rowGap || 'normal'};

  ${({ flexDirectionSm, alignItemsSm, justifyContentSm }) => media.sm`
     flex-direction: ${flexDirectionSm};
     align-items: ${alignItemsSm};
     justify-content: ${justifyContentSm}
  `};

  ${({ flexDirectionMd, alignItemsMd, justifyContentMd }) => media.md`
     flex-direction: ${flexDirectionMd};
     align-items: ${alignItemsMd};
     justify-content: ${justifyContentMd}
  `};

  ${({ flexDirectionLg, alignItemsLg, justifyContentLg }) => media.lg`
     flex-direction: ${flexDirectionLg};
     align-items: ${alignItemsLg};
     justify-content: ${justifyContentLg}
  `};
`;
