import styled from 'styled-components';
import { FlexBox } from 'shared/ui/atoms/flexbox';

export const StyledList = styled(FlexBox)`
    height: 32px;
    
    & > div {
        position: relative;
        z-index: 1;
        
        &:hover {
            z-index: 4;
        }
    }
    & > div + div {
        margin-left: -10px;
    }
`;
