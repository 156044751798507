import { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'shared/ui/styles/theme';
import { GlobalStyles } from 'shared/ui/styles/global';

// eslint-disable-next-line react/display-name
export const withThemeProvider = (component: () => ReactNode) => () => (
    <ThemeProvider theme={theme}>
        <GlobalStyles />
        {component()}
    </ThemeProvider>
);
