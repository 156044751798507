import { ContentBox } from 'shared/ui/atoms/content-box';
import { useSetAppHeader } from 'widgets/header/model/store';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import { Typography } from 'shared/ui/atoms/typography';
import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { SectionWhiteBox } from 'shared/ui/atoms/section-white-box';
import { Spacing } from 'shared/ui/atoms/spacing';
import { useFaqQuery } from 'entities/faq/model/faq';
import { Grid } from '../../shared/ui/atoms/grid';
import { useEffect, useState } from 'react';
import { FaqItem } from '../../entities/faq/ui/faq-item';
import { useScreenDetect } from '../../shared/hooks/use-screen-detect';
import React from 'react';
import { Separator } from '../../shared/ui/atoms/separator';
import {
    SearchTextInput,
    StyledContentGrid,
    StyledNavItem,
    StyledSearchContainer
} from './styles';

const Faq = () => {
    const [query, setQuery] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
    const { data } = useFaqQuery();
    const { isMobile } = useScreenDetect();

    useEffect(() => {
        if (data?.length) {
            setSelectedCategory(data[0].id);
        }
    }, [data]);

    const faqItems = data?.find((item) => item.id === selectedCategory)?.items ?? [];

    const isSearchState = !!query.trim().length;

    const allItems = data?.flatMap((item) => item?.items);

    const filteredItems = isSearchState
        ? allItems?.filter(
            (item) =>
                item.text.toLowerCase().includes(query.toLowerCase()) ||
                  item.name.toLowerCase().includes(query.toLowerCase())
        )
        : faqItems;

    useSetAppHeader(
        isMobile ? (
            <Typography color='linksIcons' size='h_5' bold uppercase>
                Frequently asked questions
            </Typography>
        ) : (
            <FlexBox alignItems='center'>
                <Typography color='buttons' size='h_1' uppercase>
                    Frequently asked questions
                </Typography>
            </FlexBox>
        )
    );

    return (
        <ContentBox>
            <StyledContentGrid columnsMd='1fr minmax(max-content, 266px)' columnGap={25} rowGap={25}>
                <SectionWhiteBox>
                    <StyledSearchContainer>
                        <SvgIcon icon='search' width={24} />
                        <SearchTextInput
                            placeholder='Type in a question'
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                        />
                        {isSearchState && (
                            <SvgIcon
                                icon='cross'
                                width={16}
                                color='black2'
                                onClick={() => setQuery('')}
                            />
                        )}
                    </StyledSearchContainer>

                    <Spacing mt='30px'>
                        <Grid
                            columnsMd={
                                isSearchState ? undefined : '3fr max-content'
                            }
                            xs={1}
                            md={isSearchState ? 1 : 2}
                            columnGap={60}
                            rowGap={16}
                        >
                            <div>
                                {filteredItems?.map((item, index) => (
                                    <React.Fragment key={item.id}>
                                        <FaqItem
                                            data={item}
                                            defaultOpened={index === 0}
                                        />
                                        {index !== filteredItems.length - 1 && (
                                            <Separator
                                                thickness={1}
                                                spacingTop={24}
                                                spacingBottom={34}
                                            />
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </Grid>
                    </Spacing>
                </SectionWhiteBox>

                <div>
                    <Spacing mb='17px'>
                        <Typography size='h_3' color='black1' bold>
                            FAQ Topics
                        </Typography>
                    </Spacing>

                    <SectionWhiteBox>
                        {data?.map((item) => (
                            <StyledNavItem
                                active={
                                    selectedCategory === item.id
                                }
                                key={item.id}
                                pt='8px'
                                pb='8px'
                                pl={isMobile ? '15px' : '0'}
                                pr={isMobile ? '15px' : '0'}
                            >
                                <Typography
                                    color={
                                        selectedCategory === item.id
                                            ? 'linksIcons'
                                            : 'black1'
                                    }
                                    onClick={() =>
                                        setSelectedCategory(item.id)}
                                    size='h_5'
                                    className='pointer'
                                    bold
                                >
                                    {item.name}
                                </Typography>
                            </StyledNavItem>
                        ))}
                    </SectionWhiteBox>
                </div>
            </StyledContentGrid>
        </ContentBox>
    );
};

export default Faq;
