import { useEffect } from 'react';
import { UserApi } from '../../shared/api/user/api';
import { useNavigate } from 'react-router-dom';

export const AuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        UserApi.authCallback('google', window.location.search).then((res) => {
            const token = res.data.token;

            navigate('/');
        });
    }, [navigate]);

    return (
        <div>
            Authorizing...
        </div>
    );
};
