import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    
    iframe, video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
