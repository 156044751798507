import styled from 'styled-components';
import { SectionWhiteBox } from '../../shared/ui/atoms/section-white-box';

export const StyledListIcon = styled.div`
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #F4F8FF;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
`;

export const StyledListItem = styled(SectionWhiteBox)`
    padding: 25px;
`;
