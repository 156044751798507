import dayjs, { OpUnitType } from 'dayjs';
import isTodayPlugin from 'dayjs/plugin/isToday';
import isTomorrowPlugin from 'dayjs/plugin/isTomorrow';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utcPlagin from 'dayjs/plugin/utc';
import { Nullable } from '../types/common';

dayjs.extend(isTodayPlugin);
dayjs.extend(isTomorrowPlugin);
dayjs.extend(utcPlagin);
dayjs.extend(customParseFormat);

export const formatDateFromBe = (date: string | null) =>
    date && dayjs(date).format('DD MMM YYYY');

export const formatHoursFromBe = (date: string | null) =>
    date && dayjs(date).format('hhA');

export const isToday = (date: string | null) => date && dayjs(date).isToday();

export const isTomorrow = (date: string | null) => date && dayjs(date).isTomorrow();

export const isPastDate = (date: string | null, unit?: OpUnitType) => date && dayjs(date).isBefore(dayjs(), unit);

/**
 * TODO - implement all cases
 * @param date
 */
export const getHumanDateDefinition = (date: string | null) => {
    if (!date) {
        return 'N/A';
    }
    if  (isPastDate(date)) {
        return 'Past';
    }
    if (isToday(date)) {
        return 'Today';
    }
    if  (isTomorrow(date)) {
        return 'Tomorrow';
    }
    return 'This month';
};

export const getHumanTimeAgo = (date: string | null) => {
    if (!date) {
        return '-';
    }
    const datePast = dayjs(date);
    const dateNow = dayjs();
    const diffHours = dateNow.diff(datePast, 'h');
    const diffMins = dateNow.diff(datePast, 'm');
    const diffYears = dateNow.diff(datePast, 'y');

    if (diffMins <= 0) {
        return 'Now';
    }
    if (diffHours <= 0) {
        return `${diffMins}m`;
    }
    if (diffHours < 12) {
        return `${diffHours}H`;
    }
    if (diffHours < 24) {
        return datePast.format('hh:mmA');
    }
    if (diffYears <= 0) {
        return datePast.format('DD MMM');
    }
    return datePast.format('DD MMM YYYY');
};

export const getTimeFromTS = (ts?: Nullable<string>) => ts ? dayjs(ts).format('hh:mm A') : null;

export const getDateUTCFormat = (date: string, format: string) => {
    const parsedDate = dayjs(date, format);
    return parsedDate.utc().toISOString();
};