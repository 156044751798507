import styled, { css } from 'styled-components';
import { media } from 'shared/mixins/media';
import { Spacing } from '../spacing';

export const StyledWrapper = styled(Spacing)`
    padding: 16px;
    background: ${({ theme }) => theme.colors.background};
    border-radius: 12px;

    ${media.md(css`
        padding: 25px;
    `)}
`;
