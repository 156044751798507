import { router } from 'pages/router';
import { RouterProvider } from 'react-router-dom';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url
).toString();

const App = () => {
    return (
        <RouterProvider router={router} />
    );
};

export default App;
