import { AxiosResponse } from 'axios';
import apiClient from '../api.client';
import {
    ICountriesResponse,
    ILikeRequest,
    IStatesResponse,
    PageInstruction,
    PermissionsResponse,
    UploadResponse, WithData
} from './common.types';
import { downloadBlobToLocaleMachine } from '../../lib/file';
import { ContactUsMessageI } from 'entities/common/types/contact-us.types';

export class CommonApi {
    static upload(file: File, path: string, collection?: string): Promise<AxiosResponse<UploadResponse>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('path', path);
        if (collection) {
            formData.append('collection', collection);
        }
        return apiClient.post('/upload', formData, {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        });
    }

    static downloadFile = async (id: number, outerFileName = ''): Promise<void> => {
        try {
            const { data: blob, headers = {} } = await apiClient.post<Blob>(`/download/${id}`, undefined, { responseType: 'blob' });
            let fileName = outerFileName;
            const { contentDisposition = '' } = headers;
            const checker = 'filename=';
            const responseFileNamePair =
                (contentDisposition as string).split('; ').find((item) => item.startsWith(checker)) || '';
            if (responseFileNamePair) {
                const [, responseFileName = ''] = responseFileNamePair.split(checker);
                fileName = responseFileName.trim().replaceAll('"', '') || fileName;
            }
            await downloadBlobToLocaleMachine(blob, fileName);
        } catch (err) {
            console.error('downloadFile', err);
        }
    };

    static getPdfPreview(id: number): Promise<AxiosResponse<Blob>> {
        return apiClient.post<Blob>(`/download/${id}`, undefined, {
            responseType: 'blob'
        });
    }

    static getAllPermissions(): Promise<AxiosResponse<PermissionsResponse>> {
        return apiClient.get('common/permissions');
    }

    static like(data: ILikeRequest): Promise<AxiosResponse> {
        return apiClient.post('common/like', data);
    }

    static unlike(data: ILikeRequest): Promise<AxiosResponse> {
        return apiClient.post('common/unlike', data);
    }

    static countries(): Promise<AxiosResponse<ICountriesResponse>> {
        return apiClient.get('common/countries/list');
    }

    static states(): Promise<AxiosResponse<IStatesResponse>> {
        return apiClient.get('common/countries/states');
    }

    static pageInstructions(): Promise<AxiosResponse<WithData<PageInstruction>>> {
        return apiClient.get('common/page-instructions');
    }

    static sendContactUsMessage(data: ContactUsMessageI): Promise<AxiosResponse> {
        return apiClient.post('common/request', data);
    }
}
