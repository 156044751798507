import compose from 'compose-function';
import { withModalProvider } from './with-modal-provider';
import { withThemeProvider } from './with-theme-provider';
import { withQueryClientProvider } from './with-query-client-provider';

export const withProviders = compose(
    withThemeProvider,
    withQueryClientProvider,
    withModalProvider
);
