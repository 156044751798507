import { FC, ReactElement } from 'react';
import { autoPlacement, autoUpdate, useClick, useDismiss, useFloating, useInteractions, useTransitionStyles } from '@floating-ui/react';
import { StyledContent } from './style';

interface IProps {
    trigger: ReactElement;
    children: ReactElement;
    opened: boolean;
    setOpened: (value: boolean) => void;
}

const FloatingDropdown: FC<IProps> = ({ opened, setOpened, trigger, children }) => {
    
    const { refs, floatingStyles, context } = useFloating<HTMLDivElement>({
        open: opened,
        onOpenChange: setOpened,
        whileElementsMounted: autoUpdate,
        middleware: [
            autoPlacement({
                autoAlignment: true,
                allowedPlacements: ['bottom-start', 'bottom-end', 'top-start', 'top-end']
            })
        ]
    });

    const { styles } = useTransitionStyles(context);

    const click = useClick(context);
    const dismiss = useDismiss(context, {
        referencePressEvent: 'click'
    });

    const { getReferenceProps, getFloatingProps } = useInteractions([
        click,
        dismiss
    ]);

    return (
        <>
            <div ref={refs.setReference} {...getReferenceProps()}>
                { trigger }
            </div>
            {
                opened && (
                    <StyledContent
                        ref={refs.setFloating}
                        {...getFloatingProps()}
                        style={{ ...floatingStyles, ...styles, zIndex: 10 }}
                    >
                        { children }
                    </StyledContent>
                )
            }
        </>
    );
};

export default FloatingDropdown;
