import styled from 'styled-components';

export const StyledMobileMenuWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(50, 59, 75, 0.4);
    backdrop-filter: blur(2px);
    
    & > div:first-child {
        position: relative;
        z-index: 2;
        width: 320px;
        max-width: 90%;
        height: 100%;
        overflow-y: auto;
        background: #FFFFFF;
        
        & > svg {
            position: absolute;
            right: 16px;
            top: 16px;
        }
    }
`;

export const StyledOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
`;
