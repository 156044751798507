import apiClient from '../api.client';
import { AxiosResponse } from 'axios';
import {
    EventsResponse,
    EventResponse,
    IEventCategoriesResponse,
    ICreateEventRequest,
    LinkedEventsResponse,
    EventStakeholdersResponse,
    EventGuestsResponse,
    EventStakeholdersRequest,
    EventGuestsRequest,
    EventsWithGuestCountResponse,
    ChangeEventGuestsListRequest,
    IEventPermissions,
    EventGuestsHostsRelationsResponse,
    EventGuestsSummaryResponse,
    IStakeholderLinkToEventRequest,
    IEvent
} from './event.types';
import { Pagination } from '../common/common.types';

export class EventApi {
    static fetchAvailableEvents(payload?: { query: string }): Promise<AxiosResponse<EventsResponse>> {
        return apiClient.get('/events', { params: payload });
    }

    static fetchAllMyEvents(payload?: { query: string }): Promise<AxiosResponse<EventsResponse>> {
        return apiClient.get('/my-events', { params: payload });
    }

    static fetchVaultEvents(params?: { page?: number }): Promise<AxiosResponse<Pagination<IEvent>>> {
        return apiClient.get('/vault', { params });
    }

    static fetchEventById(id: number): Promise<AxiosResponse<EventResponse>> {
        return apiClient.get(`/events/${id}`);
    }

    public static async importGuests(eventId: number, file: File) {
        const formData = new FormData();
        formData.append('file', file);
        return apiClient.post(`/events/${eventId}/guests-import`, formData, {
            headers: {
                'Content-Type' : 'multipart/form-data'
            }
        });
    }

    static exportGuests(id: number): Promise<AxiosResponse<string>> {
        return apiClient.post(`/events/${id}/guests-export`);
    }

    static exportThankYouNotes(id: number): Promise<AxiosResponse<string>> {
        return apiClient.post(`/events/${id}/thank-you-notes-export`);
    }

    static updateVaultState(id: number, payload: { isVault: boolean }): Promise<AxiosResponse> {
        return apiClient.patch(`/events/${id}/vault`, payload);
    }

    static getGuestSummary(eventId: number): Promise<AxiosResponse<EventGuestsSummaryResponse>> {
        return apiClient.get(`/events/${eventId}/guest-summary`);
    }

    static create(data: ICreateEventRequest): Promise<AxiosResponse<EventResponse>> {
        return apiClient.post('/events', data);
    }

    static update(id: number, data: ICreateEventRequest): Promise<AxiosResponse<EventResponse>> {
        return apiClient.patch(`/events/${id}`, data);
    }

    static delete(id: number): Promise<AxiosResponse> {
        return apiClient.delete(`/events/${id}`);
    }

    static getCategories(): Promise<AxiosResponse<IEventCategoriesResponse>> {
        return apiClient.get('/event/categories');
    }

    static getLinkedEvents(id: number): Promise<AxiosResponse<LinkedEventsResponse>> {
        return apiClient.get(`/events/${id}/linked-events`);
    }

    static linkEvent(id: number, linkEventId: number): Promise<AxiosResponse> {
        return apiClient.post(`/events/${id}/link-event`, { linkEventId });
    }

    static unlinkEvent(id: number, linkEventId: number): Promise<AxiosResponse> {
        return apiClient.post(`/events/${id}/unlink-event`, { linkEventId });
    }

    static getStakeholders(id: number, params?: EventStakeholdersRequest)
        : Promise<AxiosResponse<EventStakeholdersResponse>> {
        return apiClient.get(`/events/${id}/stakeholders`, { params });
    }

    static linkStakeholder(eventId: number, data: IStakeholderLinkToEventRequest): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/link-stakeholder`, data);
    }

    static unlinkStakeholder(eventId: number, stakeholderId: number): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/unlink-stakeholder`, { stakeholderId });
    }

    static getGuests(id: number, params: EventGuestsRequest): Promise<AxiosResponse<EventGuestsResponse>> {
        return apiClient.get(`/events/${id}/guests`, { params });
    }

    static getAttendedGuests(id: number): Promise<AxiosResponse<EventGuestsResponse>> {
        return apiClient.get(`/events/${id}/attended-guests`);
    }

    static getGuestsHostsRelations(eventId: number): Promise<AxiosResponse<EventGuestsHostsRelationsResponse>> {
        return apiClient.get(`/events/${eventId}/guests-relations`);
    }

    static getEventsWithGuestsCount(): Promise<AxiosResponse<EventsWithGuestCountResponse>> {
        return apiClient.get('/events-with-guests');
    }

    static linkGuest(eventId: number, guestId: number): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/link-guest`, { guestId });
    }

    static bulkLinkGuests(eventId: number, guestsIds: number[]): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/bulk-link-guests`, { guestsIds });
    }

    static unlinkGuest(eventId: number, guestId: number): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/unlink-guest`, { guestId });
    }

    static copyGuests(eventId: number, fromEventIds: number[]): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/copy-guests`, { fromEventIds });
    }

    static changeGuestsList(eventId: number, payload: ChangeEventGuestsListRequest): Promise<AxiosResponse> {
        return apiClient.post(`/events/${eventId}/change-guest-list`, payload);
    }

    static getPermissions(eventId: number): Promise<AxiosResponse<IEventPermissions>> {
        return apiClient.get(`/events/${eventId}/permissions`);
    }
}
