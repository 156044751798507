import { StyledLoader, Wrapper } from './styles';

const Loader = () => {
    return (
        <Wrapper>
            <StyledLoader />
        </Wrapper>
    );
};

export default Loader;
