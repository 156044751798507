import styled from 'styled-components';
import { media } from '../../../mixins/media';

export const Root = styled.div`
    flex: 1;
    position: relative;

    ${media.md`
        max-width: 450px;
    `}
    
    svg {
        position: absolute;
    }
    
    & svg:first-child {
        left: 12px;
        top: 8px;
    }
    
    & svg:last-child {
        right: 12px;
        top: 14px;
    }

    input {
        background: #F4F4F4;
        border-radius: 10px;
        padding: 8px 12px 8px 48px;
        height: 40px;
        border: none;
    }
`;
