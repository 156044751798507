import { ModalMainInterface } from 'shared/modal/modal.types';
import { modalRegistry } from '../../shared/modal/providers/modal.registry';
import { lazy } from 'react';

const Ui = lazy(() => import('./ui'));

export const ContactUsModal = modalRegistry.register<ModalMainInterface>({
    id: 'ContactUsModal',
    component: Ui
});
