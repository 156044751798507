import React, { forwardRef } from 'react';
import { ITypography } from './typography.types';
import { StyledTypography } from './typography.styles';

export const Typography: React.FC<ITypography> = forwardRef(({
    children,
    tag,
    color,
    bold,
    uppercase,
    capitalize,
    altTitle,
    breakSpaces,
    overflowLines,
    onClick,
    center,
    size = 'p',
    className,
    ...props
}, ref) => (
    <StyledTypography
        {...props}
        ref={ref}
        as={tag}
        color={color}
        className={className}
        size={size}
        bold={bold}
        uppercase={uppercase}
        capitalize={capitalize}
        title={altTitle}
        breakSpaces={breakSpaces}
        overflowLines={overflowLines}
        center={center}
        onClick={onClick}
    >
        {children}
    </StyledTypography>
));

Typography.displayName = 'Typography';
