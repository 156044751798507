import styled, { css } from 'styled-components';
import { TypographyWithRef } from './typography.types';
import { getFontSize, getLineHeight } from './typography.utils';

const overflowMixin = css<TypographyWithRef>`
  max-width: 100%;
  display: -webkit-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: ${({ overflowLines }) => overflowLines ? 'normal' : undefined};
  -webkit-box-orient: ${({ overflowLines }) => overflowLines ? 'vertical' : undefined};
  -webkit-line-clamp: ${({ overflowLines }) => overflowLines || undefined};
`;

export const StyledTypography = styled.span<TypographyWithRef>`
  color: ${({ theme, color }) => theme.colors[color] ?? theme.colors.black1};
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: ${({ size }) => getFontSize(size)};
  line-height: ${({ size }) => getLineHeight(size)};
  word-break: break-word;
  -webkit-text-size-adjust: none;

  ${({ medium }) => medium && css`font-weight: 500;`}
  ${({ bold }) => bold && css`font-weight: 700;`}
  ${({ uppercase, capitalize }) =>
        ((uppercase) && css`text-transform: uppercase;`) || (capitalize && css`text-transform: capitalize;`)}

  ${({ center }) => center && css`
    -webkit-box-pack: center;
    text-align: center;
  `}
  
  ${({ breakSpaces }) => breakSpaces && css`
    white-space: break-spaces;
  `};
  
  ${({ lineThrough }) => lineThrough && css`
      text-decoration: line-through;
  `};
  
  ${overflowMixin}
    
    a {
      color: inherit;
  }
`;
