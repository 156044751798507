import { FC, MouseEvent as ReactMouseEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { IIcon } from 'shared/ui/atoms/svg-icon/svg-icon.types';
import { Typography } from 'shared/ui/atoms/typography';
import { StyledLink } from './styles';

interface IProps {
    icon: IIcon;
    label: string;
    href: string;
    onClick?: (event: ReactMouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const NavigationLink: FC<IProps> = ({ href, icon, label, onClick }) => {
    const location = useLocation();

    const pathname = location?.pathname || '/';
    let isActive = href === '/' ? (pathname === '/') : pathname.startsWith(href);

    if (href === '/' && pathname.startsWith('/event/')) {
        isActive = true;
    }
    return (
        <StyledLink isActive={isActive}>
            <Link
                onClick={onClick}
                to={href}
            >
                <SvgIcon
                    width={22}
                    icon={icon}
                    variant='menu'
                    color={isActive ? 'linksIcons' : 'uiElements'}
                />
                <Typography
                    size='h_5'
                    color={isActive ? 'linksIcons' : 'grey2'}
                    bold
                >
                    {label}
                </Typography>
            </Link>
        </StyledLink>
    );
};
