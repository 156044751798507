import App from 'app';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import 'reactjs-popup/dist/index.css';
import './shared/config/yup-extensions';

// import reportWebVitals from './reportWebVitals';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN || '';
if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [
            new Sentry.BrowserTracing()
            // new Sentry.Replay()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();