import { lazy } from 'react';
import { modalRegistry } from 'shared/modal/providers/modal.registry';
import { IModalProps } from './types';

const Ui = lazy(() => import('./ui'));

export const YouHaveReachedThePlanModal = modalRegistry.register<IModalProps>({
    id: 'YouHaveReachedThePlan',
    component: Ui
});
