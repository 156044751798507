import { Suspense, useCallback } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { ModalRegistryEntityItem } from '../../modal.types';
import { modalRegistry } from '../../providers/modal.registry';
import { StyledWrapper } from './modal-component.styles';

interface Props {
    modal: ModalRegistryEntityItem;
    hidden?: boolean;
}

const ModalComponent = ({ modal, hidden }: Props) => {
    const { id, component: Component, opened, props: modalProps } = modal;

    const closeModal = useCallback(() => {
        const modal = modalRegistry.get(id);
        modal?.close();
    }, [id]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const onOverlayClick = useCallback((e) => {
        if (e.target?.closest?.('[data-component="select"]')
            || e.target?.closest?.('[data-component="modal-component-wrapper"]')
            || e.target?.closest?.('[data-component="date-picker"]')) {
            return;
        }
        !modalProps?.preventEasyClose && closeModal();
    }, [modalProps, closeModal]);

    // @TODO: React router
    // useEffect(() => {
    //     router.events.on('routeChangeStart', closeModal);

    //     return () => {
    //         router.events.off('routeChangeStart', closeModal);
    //     };
    // }, [closeModal, router]);

    if (!Component || !opened) {
        return null;
    }

    return (
        <StyledWrapper data-component='modal-component-wrapper' hidden={hidden}>
            <OutsideClickHandler onOutsideClick={onOverlayClick} display='contents'>
                <Suspense>
                    <Component {...modalProps} closeModal={closeModal} />
                </Suspense>
            </OutsideClickHandler>
        </StyledWrapper>
    );
};

export default ModalComponent;
