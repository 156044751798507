import { YouHaveReachedThePlanModal } from '../../../modals/you-have-reached-the-plan';
import { RouteE } from '../../../shared/config/navigation';
import { Button } from '../../../shared/ui/atoms/button';
import { useNavigate } from 'react-router-dom';
import { useUserInfoQuery } from '../../../entities/user/model/user';
import { EventApi } from '../../../shared/api/event';
import { FC } from 'react';

export const CreateEventButton : FC<{ stretch?: boolean }> = ({ stretch }) => {
    const { data } = useUserInfoQuery();
    const navigate = useNavigate();

    const onClick = () => {
        data && EventApi.fetchAllMyEvents()
            .then(d => d.data.events.total)
            .then(total => {
                if (data.subscriptionPermissions.create.events > total) {
                    navigate(RouteE.EVENT_CREATE);
                } else {
                    YouHaveReachedThePlanModal.open({
                        type: 'events'
                    });
                }
            });
    };

    return (
        <Button type='button' stretch={stretch} onClick={onClick}>
            Create event
        </Button>
    );
};
