import styled, { css } from 'styled-components';
import { TextInput } from 'shared/ui/atoms/text-input';
import { media } from '../../shared/mixins/media';
import { Spacing } from '../../shared/ui/atoms/spacing';
import { Grid } from 'shared/ui/atoms/grid';

export const SearchTextInput = styled(TextInput)`
  flex: 0 0 50%;
`;

export const StyledNavItem = styled(Spacing)<{ active?: boolean }>`
    ${({ active }) => active && css`
      background: linear-gradient(0deg, rgba(243, 242, 239, 0.7), rgba(243, 242, 239, 0.7)), #F3F6F9;
  `}
    
    & + & {
        margin-top: 12px;
    }
    
    ${media.md`
        background: none;
    `}
`;

export const StyledSearchContainer = styled.div`
    position: relative;
    width: 100%;
    
    & > svg {
        position: absolute;
        top: 50%;
    }
    
    & > svg:first-child {
        left: 24px;
        margin-top: -12px;
    }
    
    & > svg:last-child {
        cursor: pointer;
        right: 24px;
        margin-top: -8px;
    }

    input {
        height: 63px;
        width: 100%;
        padding-left: 60px;
        padding-right: 60px;
    }
`;

export const StyledContentGrid = styled(Grid)`
    & > div:last-child {
        grid-row: 1;

        ${media.md(css`
            grid-row: auto;
        `)}
    }
`;
