import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: block;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.tabs};
`;

export const StyledRatio = styled.div<{ ratio?: number }>`
  display: block;
  box-sizing: border-box;
  padding-top: ${({ ratio }) => `${ratio ?? 100}%`};
`;

export const StyledImage = styled.img`
  position: absolute;
  inset: 0;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  object-fit: cover;
  filter: none;
  background-size: cover;
  background-image: none;
  background-position: 0% 0%;
`;

export const StyledStub = styled.div`
    position: absolute;
    inset: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    & img {
        width: 25%;
        max-width: 100px;
        opacity: 0.5;
    }
`;
