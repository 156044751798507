import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { PUBLIC_ROUTES, RouteE } from 'shared/config/navigation';

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-type': 'application/json'
    }
});

instance.interceptors.response.use((config) => {
    return config;
}, (error) => {
    if (error.response.status === 401) {
        const currentUrl = window.location.pathname;
        if (!PUBLIC_ROUTES.includes(currentUrl)) {
            window.location.replace(`${window.location.origin}${RouteE.SIGN_IN}`);
        }
    }

    return Promise.reject(error);
});

const apiClient = applyCaseMiddleware(instance);

export default apiClient;
