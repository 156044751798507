import { AppLayout } from 'shared/ui/app-layout';
import { Header } from 'widgets/header';
import { Outlet } from 'react-router-dom';
import { Sidebar } from 'widgets/sidebar';
import { useAppHeader } from 'widgets/header/model/store';

export const AuthorizedPageLayout = () => {
    const HeaderComponent = useAppHeader();
    
    return (
        <AppLayout>
            {/* <AppLayout.Content> */}
            <AppLayout.SideBar>
                <Sidebar />
            </AppLayout.SideBar>
            <AppLayout.Main>
                <AppLayout.Header>
                    <Header>
                        {HeaderComponent}
                    </Header>
                </AppLayout.Header>
                <AppLayout.PageContent>
                    <Outlet />
                </AppLayout.PageContent>
            </AppLayout.Main>
            {/* </AppLayout.Content>
            <Footer /> */}
        </AppLayout>
    );
};
