import { media } from 'shared/mixins/media';
import styled, { css } from 'styled-components';
import { IGridCellProps, IGridProps } from './grid.types';

export const StyledGrid = styled.div<Omit<IGridProps, 'children' | 'className'>>`
  width: 100%;
  display: grid;
  
  ${({ alignItems }) => alignItems && css`align-items: ${alignItems}`};

  ${({ rowGap }) => rowGap && `grid-row-gap: ${rowGap}px`};

  ${({ columnGap }) => columnGap && `grid-column-gap: ${columnGap}px`};

  ${({ rowGapSm }) => rowGapSm && media.sm`grid-row-gap: ${rowGapSm}px`};
  ${({ rowGapMd }) => rowGapMd && media.md`grid-row-gap: ${rowGapMd}px`};
  ${({ rowGapLg }) => rowGapLg && media.lg`grid-row-gap: ${rowGapLg}px`};
  ${({ rowGapXl }) => rowGapXl && media.xl`grid-row-gap: ${rowGapXl}px`};

  ${({ columnGapSm }) => columnGapSm && media.sm`grid-column-gap: ${columnGapSm}px`};
  ${({ columnGapMd }) => columnGapMd && media.md`grid-column-gap: ${columnGapMd}px`};
  ${({ columnGapLg }) => columnGapLg && media.lg`grid-column-gap: ${columnGapLg}px`};
  ${({ columnGapXl }) => columnGapXl && media.xl`grid-column-gap: ${columnGapXl}px`};

  ${({ xs, autoWidth }) => xs && media.xs`
    grid-template-columns: repeat(${xs}, ${autoWidth ? 'max-content' : '1fr'});
    grid-template-columns: repeat(${xs}, ${autoWidth ? '-moz-max-content' : '1fr'});
  `};

  ${({ sm, autoWidth }) => sm && media.sm`
    grid-template-columns: repeat(${sm}, ${autoWidth ? 'max-content' : '1fr'});
    grid-template-columns: repeat(${sm}, ${autoWidth ? '-moz-max-content' : '1fr'});
  `};

  ${({ md, autoWidth }) => md && media.md`
    grid-template-columns: repeat(${md}, ${autoWidth ? 'max-content' : '1fr'});
    grid-template-columns: repeat(${md}, ${autoWidth ? '-moz-max-content' : '1fr'});
  `};

  ${({ lg, autoWidth }) => lg && media.lg`
    grid-template-columns: repeat(${lg}, ${autoWidth ? 'max-content' : '1fr'});
    grid-template-columns: repeat(${lg}, ${autoWidth ? '-moz-max-content' : '1fr'});
  `};

  ${({ xl, autoWidth }) => xl && media.xl`
    grid-template-columns: repeat(${xl}, ${autoWidth ? 'max-content' : '1fr'});
    grid-template-columns: repeat(${xl}, ${autoWidth ? '-moz-max-content' : '1fr'});
  `};

    ${({ columns }) => columns && css`
        grid-template-columns: ${columns};
    `}

    ${({ columnsSm }) => columnsSm && css`
        ${media.sm`grid-template-columns: ${columnsSm}`}
    `}
    
    ${({ columnsMd }) => columnsMd && css`
        ${media.md`grid-template-columns: ${columnsMd}`}
    `}
    
    ${({ columnsLg }) => columnsLg && css`
        ${media.lg`grid-template-columns: ${columnsLg}`}
    `}
`;

export const StyledGridCell = styled.div<IGridCellProps>`
    ${({ startXs, endXs }) => startXs && endXs && css`grid-column: ${startXs} / ${endXs}`};
    ${({ startMd, endMd }) => startMd && endMd && css`
        ${media.md`grid-column: ${startMd} / ${endMd}`};
    `}
    ${({ startLg, endLg }) => startLg && endLg && css`
        ${media.lg`grid-column: ${startLg} / ${endLg}`};
    `}
`;
