import { FC, PropsWithChildren } from 'react';
import { IGridCellProps, IGridProps } from './grid.types';
import { StyledGrid, StyledGridCell } from './grid.styles';

interface IGrid extends FC<IGridProps> {
    Cell: typeof GridCell;
}

export const Grid: IGrid = ({ children, ...rest }) => (
    <StyledGrid {...rest}>
        {children}
    </StyledGrid>
);



const GridCell: FC<PropsWithChildren<IGridCellProps>> = ({ children, ...props }) => (
    <StyledGridCell {...props}>
        {children}
    </StyledGridCell>
);

Grid.Cell = GridCell;
