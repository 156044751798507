import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { ContactUsStyled } from './styles';
import { Typography } from 'shared/ui/atoms/typography';
import { ContactUsModal } from 'modals/contact-us';

const ContactUs = () => {

    const handleClick = () => {
        ContactUsModal.open({ preventEasyClose: true });
    };

    return (
        <ContactUsStyled onClick={handleClick}>
            <SvgIcon
                width={22}
                icon='contact-us'
                variant='menu'
                color='uiElements'
                
            />
            <Typography
                size='h_5'
                color='grey2'
                bold
            >
            Contact US
            </Typography>
        </ContactUsStyled>
    );
};

export default ContactUs;
