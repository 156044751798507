import { lazy } from 'react';
import { modalRegistry } from '../../shared/modal/providers/modal.registry';
import { PostEventModalProps } from './types';

const Ui = lazy(() => import('./ui'));

export const PostEventNotesModal = modalRegistry.register<PostEventModalProps>({
    id: 'PostEventNotes',
    component: Ui
});
