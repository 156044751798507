import styled, { css } from 'styled-components';
import { TTextInput } from './text-input.types';

export const StyledInput = styled.input<TTextInput>`
    height: 73px;
    padding: 26px 31px;
    outline: none;
    border-radius: 12px;
    font-size: 15px;
    line-height: 20px;
    transition: all .2s ease-in-out;

    ${({ theme, stretch }) => css`
        border: 2px solid ${theme.colors.lines};
        color: ${theme.colors.black1};
        font-family: ${theme.fonts.text};

        ${stretch && 'width: 100%'};

        &:focus {
            border-color: ${theme.colors.uiElements};
        }

        &:disabled {
            opacity: 0.6;
            border-color: ${theme.colors.neutral03};
            background-color: ${theme.colors.white};
            color: ${theme.colors.black2} !important;
            
            & + label {
                opacity: 0.6;
                color: ${theme.colors.black2} !important;
            }
        }

        &::placeholder {
            color: ${theme.colors.black1};
        }
    `}
`;
