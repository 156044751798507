import { FC } from 'react';
import { usePageInstructionsQuery } from '../../model/page-instruction';
import { StyledWrapper } from './styles';

export const PageInstruction: FC<{ slug: string, applyStyle?: boolean }> = ({ slug, applyStyle }) => {
    const { data } = usePageInstructionsQuery();

    const item = data?.find(el => el.slug === slug);

    if (!item || !item.text.trim()) {
        return null;
    }

    const text = new DOMParser()
        .parseFromString(item.text, 'text/html')
        .documentElement.textContent;

    if (!text?.trim()) {
        return null;
    }

    return <StyledWrapper $applyStyle={!!applyStyle} dangerouslySetInnerHTML={{ __html: item.text }} />;
};
