import { Spacing } from 'shared/ui/atoms/spacing';
import { FlexBox } from 'shared/ui/atoms/flexbox';
// import { Notifications } from './notifications';
import { UserProfile } from './user-profile';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { SvgIcon } from '../../../shared/ui/atoms/svg-icon';
import { useLogoutMutation } from '../../../entities/auth/model/auth';
import { Params, useLocation, Location, useNavigate, useParams } from 'react-router-dom';
import { RouteE } from '../../../shared/config/navigation';
import { PageInstruction } from '../../../entities/common/ui/page-instruction';
import { useScreenDetect } from '../../../shared/hooks/use-screen-detect';
import { Sidebar } from '../../sidebar';
import { StyledMobileMenuWrapper, StyledOverlay } from './styles';
import { Tooltip } from 'shared/ui/molecules/tooltip';

const INSTRUCTION_MAP: Record<string, string> = {
    '/': 'event-central.title.empty'
    // '/stakeholders-central': 'stakeholders-central.title',
    // '/stakeholders-central/add': 'stakeholders-details.title',
    // '/stakeholders-central/edit/:id': 'stakeholders-details.title'
};

const getRoutePath = (location: Location, params: Params): string => {
    const { pathname } = location;

    if (!Object.keys(params).length) {
        return pathname; // we don't need to replace anything
    }

    let path = pathname;
    Object.entries(params).forEach(([paramName, paramValue]) => {
        if (paramValue) {
            path = path.replace(paramValue, `:${paramName}`);
        }
    });
    return path;
};

export const Header: FC<PropsWithChildren> = ({ children }) => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const { mutateAsync: logout } = useLogoutMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const path = getRoutePath(location, params);
    const { isMobile } = useScreenDetect();

    const onLogoutClick = async () => {
        await logout();
        navigate(RouteE.SIGN_IN);
    };

    useEffect(() => {
        setMobileMenuOpen(false);
    }, [location.pathname]);

    const instruction = INSTRUCTION_MAP[path];

    return (
        <Spacing
            margin='16px'
            mtMd='30px'
            mrMd='25px'
            mbMd='30px'
            mlMd='25px'
        >
            <FlexBox alignItems={isMobile ? 'center' : undefined} justifyContent={isMobile ? undefined : 'space-between'}>
                {isMobile && (
                    <Spacing mr='16px' style={{ flex: '0 0 16px' }}>
                        <svg
                            onClick={() => setMobileMenuOpen(true)}
                            width='16'
                            height='13'
                            viewBox='0 0 16 13'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                            <path fillRule='evenodd' clipRule='evenodd' d='M0.332031 1.16797C0.332031 0.615684 0.779747 0.167969 1.33203 0.167969H14.6654C15.2177 0.167969 15.6654 0.615684 15.6654 1.16797C15.6654 1.72025 15.2177 2.16797 14.6654 2.16797H1.33203C0.779747 2.16797 0.332031 1.72025 0.332031 1.16797ZM0.332031 6.50098C0.332031 5.94869 0.779747 5.50098 1.33203 5.50098H14.6654C15.2177 5.50098 15.6654 5.94869 15.6654 6.50098C15.6654 7.05326 15.2177 7.50098 14.6654 7.50098H1.33203C0.779747 7.50098 0.332031 7.05326 0.332031 6.50098ZM1.33203 10.835C0.779747 10.835 0.332031 11.2827 0.332031 11.835C0.332031 12.3872 0.779747 12.835 1.33203 12.835H14.6654C15.2177 12.835 15.6654 12.3872 15.6654 11.835C15.6654 11.2827 15.2177 10.835 14.6654 10.835H1.33203Z' fill='#28303F' />
                        </svg>
                    </Spacing>
                )}
                {children}
                <FlexBox alignItems='center' style={isMobile ? { marginLeft: 'auto' } : {}}>
                    {/*<Notifications />*/}
                    <Spacing mr='20px' padding='10px'>
                        <Tooltip
                            trigger={(                        
                                <SvgIcon icon='logout' width={24} color='grey2' onClick={onLogoutClick} />
                            )}
                        >
                            <span>
                                Logout
                            </span>
                        </Tooltip>
                    </Spacing>
                    <UserProfile />
                </FlexBox>
            </FlexBox>
            {instruction && (
                <Spacing mt='8px'>
                    <PageInstruction slug={instruction} applyStyle />
                </Spacing>
            )}
            {isMobile && mobileMenuOpen && (
                <StyledMobileMenuWrapper>
                    <div>
                        <SvgIcon onClick={() => setMobileMenuOpen(false)} icon='cross' color='grey2' />
                        <Sidebar />
                    </div>
                    <StyledOverlay onClick={() => setMobileMenuOpen(false)} />
                </StyledMobileMenuWrapper>
            )}
        </Spacing>
    );
};
