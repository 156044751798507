import { AxiosResponse } from 'axios';
import apiClient from 'shared/api/api.client';
import { IUser } from '../auth/auth.types';
import {
    ForgotPasswordResponse,
    IChangePasswordRequest,
    INotificationsSettingsRequest,
    IUpdateUserInfoRequest,
    IUserSetting,
    ResetPasswordPayload,
    SocialsDriverT
} from './types';
import { WithData } from '../common/common.types';

export class UserApi {
    public static async getUserInfo(): Promise<AxiosResponse<IUser>> {
        return apiClient.get('/user/info');
    }

    public static async getUserSettings(): Promise<AxiosResponse<WithData<IUserSetting>>> {
        return apiClient.get('/user/settings');
    }

    public static async updateNotificationsSettings(payload: INotificationsSettingsRequest): Promise<AxiosResponse> {
        return apiClient.patch('/user/settings/notifications', payload);
    }

    public static async updateUserInfo(payload: IUpdateUserInfoRequest): Promise<AxiosResponse<IUser>> {
        return apiClient.post('/user/update', payload);
    }

    public static async getSocialsRedirectUrl(driver: SocialsDriverT): Promise<AxiosResponse<{ url: string }>> {
        return apiClient.get('/auth/google');
    }

    public static async authCallback(driver: SocialsDriverT, searchUri: string): Promise<AxiosResponse> {
        return apiClient.get(`callback/auth/${driver}${searchUri}`);
    }

    public static async changePassword(payload: IChangePasswordRequest): Promise<AxiosResponse> {
        return apiClient.post('user/change-password', payload);
    }

    public static async forgotPassword(payload: { email: string }): Promise<AxiosResponse<ForgotPasswordResponse>> {
        return apiClient.post('/user/forgot-password', payload);
    }

    public static async resetPassword(payload: ResetPasswordPayload): Promise<AxiosResponse> {
        return apiClient.post('/user/reset-password', payload);
    }
}
