import styled, { css, keyframes } from 'styled-components';
import { TButton } from './buttons.types';

const primaryKeyframes = keyframes`
    0% { width: 5%; height: auto; aspect-ratio: 1 / 1; }
    100% { width: 105%; height: auto; aspect-ratio: 1 / 1; }
`;

export const StyledButton = styled.button<TButton>`
    --border-width: 2px;
    --border-radius: 8px;
    
    position: relative;
    user-select: none;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.text};
    font-size: 15px;
    line-height: 20px;
    appearance: none;
    padding: 0 20px;
    min-height: 45px;
    margin: 0;
    z-index: 1;
    white-space: nowrap;
    
    ${({ variant }) => variant === 'none' && css`
        appearance: none;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        min-height: auto;
        height: auto;
        
        svg {
            margin: 0 !important;
        }
    `}

    ${({ theme, variant }) => variant === 'primary' && css`
        border: none;
        border-radius: 8px;
        background-color: ${theme.colors.buttons};
        color: ${theme.colors.white};
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: rgba(${theme.colors.whiteRgb}, 0.2);
            mix-blend-mode: hard-light;
            z-index: -1;
        }

        &:hover::before {
            animation: ${primaryKeyframes} .25s forwards;
        }
    `}
    
    ${({ theme, variant }) => variant === 'pink' && css`
        border: none;
        border-radius: 8px;
        background-color: ${theme.colors.linksIcons};
        color: ${theme.colors.white};
        overflow: hidden;
        
        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: rgba(${theme.colors.whiteRgb}, 0.2);
            mix-blend-mode: hard-light;
            z-index: -1;
        }

        &:hover::before {
            animation: ${primaryKeyframes} .25s forwards;
        }

        & svg {
            margin: 0 8px 0 0;
        }
    `}

    ${({ theme, variant }) => variant === 'outline' && css`
        position: relative;
        border: none;
        border-radius: var(--border-radius);
        background: linear-gradient(${theme.colors.uiElements}, ${theme.colors.uiElements});
        background-size: 50% 100%;
        transition: background-size .25s linear;
        color: ${theme.colors.uiElements};
        z-index: 1;
        
        &:hover {
            background: linear-gradient(83deg, #DB6CC6 -6%, #538689 30%, #91D6DE 68%, #9A2689 112%);
            background-size: 100% 100%;
        }
        
        &::before {
            content: '';
            position: absolute;
            inset: var(--border-width);
            border-radius: calc(var(--border-radius) - var(--border-width));
            background: ${theme.colors.white};
            z-index: -1;
        }

        &:disabled {
            background: ${theme.colors.lines};
            color: ${theme.colors.neutral04} !important;
        }
    `}
    
    ${({ theme, variant }) => variant?.includes('secondary') && css`
      position: relative;
      border-radius: var(--border-radius);
      border: none;
      background: linear-gradient(${theme.colors.lines}, ${theme.colors.lines});
      background-size: 50% 100%;
      transition: background-size .25s linear;
      color: ${theme.colors.black2};
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        inset: var(--border-width);
        border-radius: calc(var(--border-radius) - var(--border-width));
        background: ${theme.colors.white};
        z-index: -1;
      }

      &:disabled {
        font-weight: 500;
        background: ${theme.colors.lines};
        color: ${theme.colors.neutral04} !important;
      }

      &:hover {
        background: linear-gradient(83deg, #DB6CC6 -6%, #538689 30%, #91D6DE 68%, #9A2689 112%);
          
        ${variant === 'secondary-grey' && css`
          background: linear-gradient(90deg, #C1C1C1 4.61%, #A7A7A7 29.41%, #E3E3E3 56.32%, #A7A7A7 80.07%, #E3E3E3 100.33%);
        `}
        
        background-size: 100% 100%;
      }
    `}
    
    ${({ theme, variant }) => variant === 'grey' && css`
        position: relative;
        border-radius: var(--border-radius);
        border: none;
        background: linear-gradient(${theme.colors.tabs}, ${theme.colors.tabs});
        color: ${theme.colors.buttons};
        z-index: 1;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            border-radius: 50%;
            background-color: #808080;
            mix-blend-mode: hard-light;
            z-index: -1;
        }

        &:hover::before {
            animation: ${primaryKeyframes} .25s forwards;
        }
    `}
    
    ${({ fullWidth }) => fullWidth && css`
        width: 100%;
    `}

    ${({ size }) => size === 'small' && css`
        min-height: 40px;
    `}

    ${({ size }) => size === 'medium' && css`
        min-height: 60px;
    `}
    
    ${({ size }) => size === 'large' && css`
        min-height: 70px;
    `}
    
    ${({ stretch }) => stretch && css`
        width: 100%;
    `}
    
    &:disabled {
        pointer-events: none;
        color: ${({ theme }) => theme.colors.tabText};
        background-color: ${({ theme }) => theme.colors.tabs};
        
        svg {
            color: currentColor;
        }
    }

    & svg, & img {
        margin: 0 12px 0 0;
    }
    ${({ variant }) => variant === 'pink' && css`
        & svg {
            margin: 0 6px 0 0 ;
        }
    `}
`;
