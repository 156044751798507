import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthApi } from 'shared/api/auth';

export const useLogoutMutation = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['auth/logout'],
        mutationFn: () => AuthApi.logout(),
        onSuccess: () => queryClient.clear()
    });
};
