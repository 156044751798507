import styled from 'styled-components';

export const StyledContent = styled.div`
  padding: 15px 20px;
  line-height: 36px;
  min-width: 120px;
  &:focus-visible {
    outline: none;
    border: none;
}
`;
