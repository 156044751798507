import { FC, PropsWithChildren, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { StyledAuthLayout, StyledAuthLayoutContent, StyledLogo } from './styles';
import { UserApi } from '../../shared/api/user/api';

interface IAuthLayout extends FC<PropsWithChildren> {
    Content: typeof AuthLayoutContent;
}

export const AuthLayout: IAuthLayout = () => {
    const navigate = useNavigate();

    useEffect(() => {
        UserApi.getUserInfo()
            .then(() => navigate('/'))
            .catch(console.warn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <StyledAuthLayout>
            <a href='https://swankey.com' target='_blank' rel='noreferrer' >
                <StyledLogo>
                    <img src='/images/sign-logo.svg' />
                </StyledLogo>
            </a>
            <Outlet />
        </StyledAuthLayout>
    );
};

const AuthLayoutContent: FC<PropsWithChildren<{ placeMiddle?: boolean }>> = ({ children, placeMiddle }) => (
    <StyledAuthLayoutContent padding='0 16px 40px' paddingMd='0' placeMiddle={placeMiddle}>
        {children}
    </StyledAuthLayoutContent>
);

AuthLayout.Content = AuthLayoutContent;
