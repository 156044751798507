import styled, { css } from 'styled-components';
import { ISeparator } from './types';

export const StyledSeparator = styled.div<ISeparator>`
    text-align: center;
    position: relative;

    ${({ theme, spacingTop, spacingBottom, contentSpacing, thickness, color }) => css`
        padding: ${spacingTop ?? 0}px 0 ${spacingBottom ?? 0}px 0;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            height: ${thickness}px;
            width: calc(50% - ${contentSpacing ?? 0}px);
            background-color: ${theme.colors[color ?? 'background']};
        }
    `}

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }

    & > div {
        position: relative;
        display: inline;
        font-size: 14px;
        line-height: 30px;
        background-color: white;
        z-index: 2;
    }
`;
