import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #FFFFFF;
    border: 1.5px solid ${({ theme }) => theme.colors.tabText};
    user-select: none;
    width: 32px;
    height: 32px;
    border-radius: 50%;
`;
