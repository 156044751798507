import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { UserApi } from 'shared/api/user/api';
import { ForgotPasswordResponse, IChangePasswordRequest, IUpdateUserInfoRequest, ResetPasswordPayload } from 'shared/api/user/types';
import { invalidateQueryKeys } from 'shared/lib/api';

export const useUserInfoQuery = () => useQuery({
    queryKey: ['user/info'],
    queryFn: () => UserApi.getUserInfo()
        .then((response) => response.data)
});

export const useUpdateUserInfoQuery = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationKey: ['user/info/update'],
        mutationFn: (payload: IUpdateUserInfoRequest) => UserApi.updateUserInfo(payload),
        onSuccess: async () => invalidateQueryKeys(queryClient, ['user/info'])
    });
};

export const useUpdateUserPasswordMutation = () => useMutation({
    mutationKey: ['user/password-update'],
    mutationFn: (payload: IChangePasswordRequest) => UserApi.changePassword(payload)
});

export const useForgotPassword = () => useMutation({
    mutationKey: ['user/forgot-password'],
    mutationFn: (email: string) => {
        return UserApi.forgotPassword({ email });
    },
    onError: (error: AxiosError<ForgotPasswordResponse>) => error
});

export const useResetPassword = () => useMutation({
    mutationKey: ['user/reset-password'],
    mutationFn: (data: ResetPasswordPayload) => {
        return UserApi.resetPassword(data);
    },
    onError: (error: AxiosError<ForgotPasswordResponse>) => error
});
