import { FC, useRef, useState } from 'react';
import { SvgIcon } from '../../atoms/svg-icon';
import { Typography } from '../../atoms/typography';
import { StyledContent } from './styles';
import { Color } from 'shared/types/theme.types';
import { IThreeDotsAction } from './types';
import FloatingDropdown from '../floating-dropdown';

interface IProps {
    actions: IThreeDotsAction[];
    horizontalBindTo?: 'left' | 'right';
    color?: Color;
}

export const ThreeDotsActionsMenu: FC<IProps> = ({ actions, horizontalBindTo = 'right', color }) => {
    const [opened, setOpened] = useState<boolean>(false);

    const menuRef = useRef(null);

    return (
        <>
            <FloatingDropdown
                trigger={
                    <SvgIcon ref={menuRef} icon='three-dots' variant='interface' color={color} />
                }
                opened={opened}
                setOpened={setOpened}
            >
                <StyledContent>
                    {actions.map(action => (
                        <Typography
                            onClick={() => {
                                action.onClick();
                                setOpened(false);
                            }}
                            color={action.color as Color}
                            className='pointer'
                            tag='div'
                            size='h_5'
                            key={action.label}
                            bold
                        >
                            {action.label}
                        </Typography>
                    ))}
                </StyledContent>
            </FloatingDropdown>
        </>
    );
};
