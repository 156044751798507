import { media } from 'shared/mixins/media';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
    display: flex;
    height: 100%;
    background: #FFFFFF;
`;

export const StyledContent = styled.div`
    display: flex;
`;

export const StyledSidebarWrapper = styled.div`
    display: none;
    height: 100%;
    flex: 0 0 264px;
    background: #FFFFFF;
    border-right: 1px solid #F3F3F2;
    
    ${media.sm`
        display: block;
    `}

    ${media.lg`
        flex: 0 0 274px;
    `}
`;

export const StyledMainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
`;

export const StyledContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;
