import { useMemo } from 'react';
import dayjs from 'dayjs';
import { pluralize } from 'shared/lib/pluralize';
import { IEvent } from 'shared/api/event';

export const useTimeLeft = (time: IEvent['endedAt']) => useMemo(() => {
    const date = dayjs(time || undefined);
    const m = date.diff(dayjs(), 'month');
    const w = date.diff(dayjs(), 'weeks');
    const d = date.diff(dayjs(), 'd');
    let label = `${pluralize(d, 'day')} left`;
    if (d === 0) {
        label = 'Today';
    }
    if (d < 0) {
        label = 'Ended';
    }
    if (w > 0) {
        label = `${pluralize(w, 'week')} left`;
    }
    if (m > 0) {
        label = `${pluralize(m, 'month')} left`;
    }

    return { d, m, w, label };
}, [time]);

