import styled, { css } from 'styled-components';
import { media } from '../../../../shared/mixins/media';

export const StyledWrapper = styled.div<{ $applyStyle: boolean }>`
  & > p {
      margin: 0;
  }
    
  ${({ $applyStyle }) => $applyStyle && css`
      display: inline-block;
      background: #FCFCFC;
      color: #8083A3;
      border: 1px solid rgba(83,134,137,0.4);
      box-shadow: 0 4px 8px -4px rgba(0,0,0,0.06), inset 0px -1px 1px rgba(0,0,0,0.04), inset 0px 2px 0px rgba(255,255,255,0.25);
      border-radius: 11px;
      padding: 7px 14px;
      font-weight: 500;
      font-size: 12px;
      
      ${media.md`
        font-size: 14px;
      `}
  `}
`;
