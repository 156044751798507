import styled from 'styled-components';

export const StyledLink = styled.div<{ isActive: boolean }>`
    padding: 12px 15px;
    background: ${({ isActive }) => isActive ? '#F3F3F2' : 'transparent'};
    mix-blend-mode: multiply;
    border-radius: 11px;
    
    & + & {
        margin-top: 7px;
    }
    
    & a {
        text-decoration: none;
        display: flex;
        align-items: center;
    }
    
    & svg {
        margin-right: 16px;
    }
`;
