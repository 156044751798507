import { AxiosResponse } from 'axios';
import apiClient from '../api.client';
import { IConfirmEmailRequest, IConfirmEmailResponse, ISignInRequest, ISignInResponse, ISignUpRequest, ISignUpResponse } from './auth.types';

export class AuthApi {
    public static async updateCsrfToken(): Promise<AxiosResponse> {
        return apiClient.get('/sanctum/csrf-cookie', { baseURL: process.env.REACT_APP_API_URL });
    }

    public static async signIn(data: ISignInRequest): Promise<AxiosResponse<ISignInResponse>> {
        return apiClient.post('/user/login', data);
    }

    public static async signUp(data: ISignUpRequest): Promise<AxiosResponse<ISignUpResponse>> {
        return apiClient.post('/user/register', data);
    }

    public static async confirmEmail(data: IConfirmEmailRequest): Promise<AxiosResponse<IConfirmEmailResponse>> {
        return apiClient.post('/email/verify', data);
    }

    public static async logout(): Promise<AxiosResponse> {
        return apiClient.post('/user/logout');
    }
}
