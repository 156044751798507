import React, { FC, PropsWithChildren } from 'react';
import { StyledContent, StyledContentWrapper, StyledMainWrapper, StyledSidebarWrapper, StyledWrapper } from './styles';

type IProps = FC<PropsWithChildren> & {
    Header: typeof Header;
    PageContent: typeof PageContent;
    SideBar: typeof SideBar;
    Main: typeof Main;
    Content: typeof Content
};

export const AppLayout: IProps = ({ children }) => (
    <StyledWrapper>
        {children}
    </StyledWrapper>
);

export const Content: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledContent>
        { children }
    </StyledContent>
); 

const Header: React.FC<PropsWithChildren> = ({ children }) => (
    <div>
        {children}
    </div>
);

const Main: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledMainWrapper>
        {children}
    </StyledMainWrapper>
);

const SideBar: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledSidebarWrapper>
        {children}
    </StyledSidebarWrapper>
);

const PageContent: React.FC<PropsWithChildren> = ({ children }) => (
    <StyledContentWrapper>
        {children}
    </StyledContentWrapper>
);

AppLayout.Header = Header;
AppLayout.SideBar = SideBar;
AppLayout.Main = Main;
AppLayout.PageContent = PageContent;
AppLayout.Content = Content;
