import { FC, useCallback, useState } from 'react';
import { SvgIcon } from '../../atoms/svg-icon';
import { TextInput } from '../../atoms/text-input';
import { useDebouncedEffect } from '../../../hooks/use-debounced-effect';
import { Root } from './styles';

export const SearchInput: FC<{
    placeholder: string;
    onChange: (value: string) => void;
    onReset: () => void;
    isLoading?: boolean;
    className?: string;
}> = ({ placeholder, isLoading, onChange, onReset, className }) => {
    const [value, setValue] = useState('');

    const handleKeyDown = useCallback((e: any) => {
        if (e.key === 'Enter') {
            setValue(e.target.value);
            onChange(e.target.value);
        }
    }, [onChange]);

    const search = () => onChange(value);

    useDebouncedEffect(search, 400, [value]);

    return (
        <Root className={className}>
            <SvgIcon icon='search' width={24} />
            <TextInput
                disabled={isLoading}
                placeholder={placeholder}
                value={value}
                onKeyDown={handleKeyDown}
                onChange={(e) => setValue(e.target.value)}
                stretch
            />
            {!!value && <SvgIcon
                onClick={() => {
                    onReset();
                    setValue('');
                }}
                icon='cross'
                width={12}
            />}
        </Root>
    );
};
