import { FC } from 'react';
import { IUser } from 'shared/api/auth/auth.types';
import { StyledImage } from './styles';

interface IProps {
    user?: IUser;
    size?: number;
    title?: string;
}

export const UserAvatar: FC<IProps> = ({ title, user, size = 66 }) => {
    const avatar = user?.avatar ?? user?.media?.path ?? '/icons/swankey-grey.svg';
    return (
        <StyledImage title={title} src={avatar} width={size} height={size} />
    );
};
