import styled, { css } from 'styled-components';
import { FlexBox } from 'shared/ui/atoms/flexbox';

export const StyledIcon = styled(FlexBox)`
    width: 32px;
    height: 32px;
    margin-right: 22px;
    background: #EBEBEB;
    border-radius: 50%;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -18px;
        width: 2px;
        height: 18px;
        background-color: #4B5563;
    }
`;

export const StyledNavItem = styled(FlexBox)<{ active?: boolean }>`
    cursor: pointer;
    
    ${({ active, theme }) => active && css`
        ${StyledIcon} {
            height: 46px;
            border-radius: 160px;
            background: ${theme.colors.mainNavLinks};
            &::after {
                background-color: ${theme.colors.mainNavLinks};
            }
        }
  `}
    
    ${({ active }) => !active && css`
        ${StyledIcon} {
            opacity: 0.6;
        }
    `}

    &:last-child {
        & ${StyledIcon} {
            &::after {
                display: none;
            }
        }
    }
`;
