import styled, { css } from 'styled-components';
import { IProps } from './types';

export const StyledWrapper = styled.label<IProps & { clickable: boolean }>`
    display: inline-block;
    background: ${({ theme, type }) => type === 'warning' ? `
        linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #9A2689;
    ` : theme.colors.background};
    border-radius: 7px;
    padding: 7px 12px;
    
    ${({ bgColor, theme }) => bgColor && `
        background: ${theme.colors[bgColor]};
    `}
    
    ${({ clickable }) => css`
        cursor: ${clickable ? 'pointer' : 'default'};
    `}
`;
