import { forwardRef } from 'react';
import { TSvgIcon } from './svg-icon.types';
import { StyledSvg } from './svg-icon.styles';

export const SvgIcon = forwardRef<SVGSVGElement, TSvgIcon>(({
    width = 18,
    height,
    icon,
    color = 'black2',
    customColor,
    variant = 'regular',
    ...props
}, ref) => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <StyledSvg
            ref={ref}
            xmlns='http://www.w3.org/2000/svg'
            width={width}
            height={height ?? width}
            color={color}
            customColor={customColor}
            {...props}
        >
            <use xlinkHref={`/icons/${variant}.svg#${icon}`} />
        </StyledSvg>
    );
});

SvgIcon.displayName = 'Icon';
