import { useSetAppHeader } from '../../widgets/header/model/store';
import { FlexBox } from '../../shared/ui/atoms/flexbox';
import { Typography } from '../../shared/ui/atoms/typography';
import { ContentBox } from 'shared/ui/atoms/content-box';
import { ListItem } from './list-item';
import { Grid } from 'shared/ui/atoms/grid';
import { StyledListItem } from './styles';
import { useHowToCategoriesQuery } from '../../entities/how-to/model/how-to';
import { generatePath, useNavigate } from 'react-router-dom';
import { RouteE } from '../../shared/config/navigation';
import { useScreenDetect } from 'shared/hooks/use-screen-detect';

const HowTo = () => {
    const { isMobile } = useScreenDetect();
    const navigate = useNavigate();
    const { data } = useHowToCategoriesQuery();

    useSetAppHeader(
        isMobile ? (
            <Typography size='h_5' color='linksIcons' bold uppercase>How To</Typography>
        ) : (
            <FlexBox alignItems='center'>
                <Typography color='buttons' size='h_1' uppercase>
                    How To
                </Typography>
            </FlexBox>
        )
    );

    const sortedData = [...(data ?? [])].sort((a, b) => a.order - b.order);

    return (
        <ContentBox>
            <Grid rowGap={15}>
                {sortedData?.map((item) => (
                    <StyledListItem key={item.id} className='pointer'>
                        <ListItem
                            data={item}
                            onClick={() => navigate(generatePath(RouteE.HOW_TO_ITEM, { id: item.id }))}
                        />
                    </StyledListItem>
                ))}
            </Grid>
        </ContentBox>
    );
};

export default HowTo;
