import { FC, PropsWithChildren } from 'react';
import { Wrapper } from './styles';

export const Video: FC<PropsWithChildren & { className?: string }> = ({ className, children }) => {
    return (
        <Wrapper className={className}>
            {children}
        </Wrapper>
    );
};
