import { SwankeyTheme } from 'shared/types/theme.types';

export const theme: SwankeyTheme = {
    colors: {
        main: '#30394A',
        buttons: '#0D3B66',
        mainNavLinks: '#E5625C',
        mainNavLinksRgba: '254, 109, 115',
        categoryIcons: '#689CB4',
        uiElements: '#538689',
        uiElementsRgb: '83, 134, 137',
        linksIcons: '#9A2689',
        icons: '#DB6CC6',
        red2: '#FE8A8F',
        errors: '#FF6A55',
        black: '#000000',
        blackRgb: '0, 0, 0',
        black1: '#2A2A31',
        black2: '#4B5563',
        grey1: '#8E929C',
        grey1Rgb: '142, 146, 156',
        grey2: '#8083A3',
        grey3: '#747474',
        neutral02: '#F4F4F4',
        neutral03: '#EFEFEF',
        neutral04: '#6F767E',
        neutral07: '#1A1D1F',
        lines: '#E3E3E3',
        cards1: '#E1EBF0',
        cards2: '#E7EEEE',
        cards3: '#EBD4E7',
        background: '#F3F3F2',
        tabs: '#F0F3F7',
        tabText: '#27323F',
        white: '#FFFFFF',
        whiteRgb: '255, 255, 255',
        overlay: '#323B4B',
        overlayRgba: '50, 59, 75',
        primaryShadow: '55, 125, 255',
        text4: '#B0B7C3',
        outlineRgba: '228, 230, 232',
        darkYellow: '#DA9F41',
        mainNeutral: '#f5e1ff'
    },
    fonts: {
        text: 'DM Sans, sans-serif'
    }
};
