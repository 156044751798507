import { AxiosResponse } from 'axios';
import apiClient from 'shared/api/api.client';
import { DataResponse, WithData } from '../common/common.types';
import { HowToCategory, HowToCategoryByIdResponse } from './how-to.types';

export class HowToApi {
    public static async fetchCategories(): Promise<AxiosResponse<WithData<HowToCategory>>> {
        return apiClient.get('/how-to/categories');
    }

    public static async fetchCategory(id: string): Promise<AxiosResponse<DataResponse<HowToCategoryByIdResponse>>> {
        return apiClient.get(`/how-to/categories/${id}`);
    }
}
