import { css } from 'styled-components';
import { Property } from 'csstype';

type TTransitionFunction = (
    properties: string[],
    duration?: number,
    easing?: Property.AnimationTimingFunction
) => ReturnType<typeof css>;

export const transition: TTransitionFunction = (properties, duration = 0.2, easing = 'ease-in-out') =>
    css`
        transition: ${properties.map((property) => `${property} ${duration}s ${easing}`).join(', ')};
    `;
