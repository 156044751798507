import React from 'react';
import { ModalVariantT, ModalRegistryItem } from '../modal.types';

interface ModalEntityArgs extends ModalRegistryItem {
    onOpen: () => void;
    onClose: () => void;
}

export class ModalEntity<T> {
    public id: ModalVariantT;

    public component: React.ElementType<any>;

    public order = 0;

    public opened = false;

    public props: any = {};

    private onOpen: ModalEntityArgs['onOpen'];

    private onClose: ModalEntityArgs['onClose'];

    constructor(props: ModalEntityArgs) {
        this.id = props.id;
        this.component = props.component;
        this.order = props.order ?? 0;
        this.onOpen = props.onOpen;
        this.onClose = props.onClose;
    }

    open(props: T) {
        this.opened = true;
        this.props = props;
        this.onOpen?.();
    }

    close() {
        this.opened = false;
        this.onClose?.();
    }
}
