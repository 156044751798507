import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    *,
    *::before,
    &::after {
      box-sizing: border-box;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
    }
    
    body {
      font-family: DMMedium, sans-serif;
    }
    
    span {
      font-family: DMBold, sans-serif;
    }
    
    a {
        text-decoration: none;
    }
    
    .pointer {
        cursor: pointer;
    }

    :root {
        --toastify-z-index: 9999999;
    }
`;
