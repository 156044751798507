import styled, { css } from 'styled-components';
import { transition } from 'shared/mixins/transition';

export const StyledWrapper = styled.div<{ active: boolean }>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  ${transition(['opacity', 'visibility'], .2)};
  
  ${({ active }) => active && css`
    visibility: visible;
    opacity: 1;
  `};
`;

export const StyledOverlay = styled.div`
  background: rgba(${({ theme }) => theme.colors.overlayRgba}, 0.4);
  backdrop-filter: blur(2px);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;
