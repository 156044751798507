import { addMethod, string } from 'yup';
import { phoneRegExp } from './validation';

// For validate minimum length of string
// except if string is empty
addMethod(string, 'softmin', function (args) {
    const { message } = args;

    return this.test('softmin', message, function (value) {
        const { path, schema, createError } = this;

        return value?.length === 0
            ? true
            : value && value.length >= args
                ? true
                : createError({
                    path,
                    message: `${schema.spec.label} must be at least ${args} characters`
                });
    });
});

// Phone validation. Check phone only if not empty
addMethod(string, 'phone', function (message) {
    return this.test('phone', message, function (value) {
        const { path, createError } = this;

        const match = value?.match(phoneRegExp);

        if (!value?.length || value === '+') {
            return true;
        }

        return !match
            ? createError({
                path,
                message
            })
            : true;
    });
});

