import { AxiosResponse } from 'axios';
import apiClient from 'shared/api/api.client';
import { WithData } from '../common/common.types';
import { FaqCategory } from './faq.types';

export class FaqApi {
    public static async fetchAll(): Promise<AxiosResponse<WithData<FaqCategory>>> {
        return apiClient.get('/faq');
    }
}
