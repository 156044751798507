import { useQuery } from '@tanstack/react-query';
import { HowToApi } from '../../../shared/api/how-to';

export const useHowToCategoriesQuery = () => useQuery({
    queryKey: ['how-to/categories'],
    // cacheTime: 0,
    // staleTime: 0, // can contain base64 images, so better skip caching
    queryFn: () => HowToApi.fetchCategories().then(d => d.data.data)
});

export const useHowToCategoryQuery = (id: string) => useQuery({
    queryKey: ['how-to/category', id],
    cacheTime: 0,
    staleTime: 0, // can contain base64 images, so better skip caching
    queryFn: () => HowToApi.fetchCategory(id).then(d => d.data.data)
});
