import { ReactNode } from 'react';
import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            staleTime: 2 * (60 * 1000), // 2 mins
            cacheTime: 5 * (60 * 1000) // 5 mins, shoud be always bigger then staleTime
        }
    }
});

// eslint-disable-next-line react/display-name
export const withQueryClientProvider = (component: () => ReactNode) => () => (
    <QueryClientProvider client={queryClient}>
        {component()}
    </QueryClientProvider>
);
