import styled, { css } from 'styled-components';
import { Badge } from 'shared/ui/atoms/badge';
import { UserList } from '../user-list';
import { StyledTextWrapperProps } from './types';
import { media } from 'shared/mixins/media';

export const StyledWrapper = styled.div<{ isUpcoming: boolean }>`
    padding: 15px;
    background: white;
    border: 6px solid rgba(109, 173, 203, 0.11);
    border-radius: 14px;

    ${({ isUpcoming }) =>
        isUpcoming &&
        css`
            border: 6px solid #f8eff9;
        `}
`;

export const StyledBadge = styled(Badge)`
    padding: 3px 7px;
`;

export const StyledUserList = styled(UserList)`
    height: 30px;
`;

export const StyledTextWrapper = styled.div<StyledTextWrapperProps>`
    ${({ widthXs, widthSm, widthLg }) => css`
        max-width: ${widthXs}px;

        ${media.sm`
            max-width: ${widthSm}px;
        `}

        ${media.lg`
            max-width: ${widthLg}px;
        `}
    `}
    span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;
