import { useCallback, useEffect } from 'react';
import { debounce } from 'shared/lib/debounce';

type EventCallback = (e?: Event) => void;

export const useWindowListener = (
    event: string,
    cb: EventCallback,
    debounceTime = 100,
    disabled = false
) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedCallback = useCallback(debounce(cb, debounceTime), [cb]);

    useEffect(() => {
        if (!disabled) {
            window.addEventListener(event, debouncedCallback);
        }

        return () => {
            window.removeEventListener(event, debouncedCallback);
        };
    }, [disabled, debouncedCallback, event]);
};

