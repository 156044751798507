import { useLayoutEffect, useRef, useState } from 'react';

const useElementDimension = <T extends HTMLElement> () => {
    const targetRef = useRef<T>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });


    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.clientWidth,
                height: targetRef.current.clientHeight
            });
        }
    }, []);

    return {
        targetRef,
        dimensions
    };
};

export default useElementDimension;