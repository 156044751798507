/**
 * Libs
 */
import styled from 'styled-components';

/**
 * Types
 */
import { TSvgIcon } from './svg-icon.types';

export const StyledSvg = styled.svg<TSvgIcon>`
  cursor: pointer;
  color: ${({ theme, color, customColor }) => customColor ?? theme.colors[color ?? 'black2']};
  flex: ${({ width }) => `0 0 ${width}px`};
`;
