import styled, { css } from 'styled-components';

export const StyledWrapper = styled.div<{ hidden?: boolean, zIndex?: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow-y: auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ hidden }) => hidden && css`
    visibility: hidden;
    opacity: 0;
  `}
`;
