import { AxiosResponse } from 'axios';
import apiClient from 'shared/api/api.client';
import { WithData } from '../common/common.types';
import { VideoTutorialCategory } from './video-tutorial.types';

export class VideoTutorialApi {
    public static async fetchAll(): Promise<AxiosResponse<WithData<VideoTutorialCategory>>> {
        return apiClient.get('/video-tutorials');
    }
}
