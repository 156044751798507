import { FC, useState } from 'react';
import { FaqItem as Item } from 'shared/api/faq';
import { Typography } from 'shared/ui/atoms/typography';
import { Spacing } from 'shared/ui/atoms/spacing';
import { FlexBox } from 'shared/ui/atoms/flexbox';
import { SvgIcon } from 'shared/ui/atoms/svg-icon';
import { useScreenDetect } from 'shared/hooks/use-screen-detect';

export const FaqItem: FC<{ data: Item, defaultOpened?: boolean }> = ({ data, defaultOpened }) => {
    const [isOpen, setIsOpen] = useState(!!defaultOpened);
    const { isMobile } = useScreenDetect();

    return (
        <div>
            <FlexBox
                className='pointer'
                onClick={() => setIsOpen(!isOpen)}
                alignItems='center'
                justifyContent='space-between'
            >
                <Typography size={isMobile ? 'h_3' : 'h_2'} bold>
                    {data.name}
                </Typography>
                <SvgIcon
                    color={!isOpen ? 'linksIcons' : 'black1'}
                    variant='interface'
                    icon={isOpen ? 'dropdown-close' : 'dropdown-open'}
                    width={24}
                />
            </FlexBox>
            {isOpen && (
                <Spacing mt='24px'>
                    <Typography size={isMobile ? 'p_small' : 'h_5'} color='neutral04' medium>
                        <div dangerouslySetInnerHTML={{ __html: data.text }} />
                    </Typography>
                </Spacing>
            )}
        </div>
    );
};
