import styled, { css } from 'styled-components';
import { media } from 'shared/mixins/media';

export const StyledWrapper = styled.div`
    border-radius: 12px;
    padding: 25px;

    ${({ theme }) => css`
        background-color: ${theme.colors.white};
    `};
    
    ${media.md(css`
        border-radius: 15px;
        padding: 35px 30px;
        align-self: start;
    `)}
`;
