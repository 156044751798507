import create from 'zustand';
import { ReactNode, useEffect } from 'react';

interface HeaderState {
    component: ReactNode;
    setHeader: (component: ReactNode) => void;
}

const useHeaderStore = create<HeaderState>((set) => ({
    component: null,
    setHeader: (component) => set((state) => ({ component }))
}));

export const useSetAppHeader = (component: ReactNode) => {
    const setHeader = useHeaderStore((state) => state.setHeader);

    useEffect(() => {
        setHeader(component);
    }, [setHeader, component]);

    useEffect(() => () => {
        setHeader(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export const useAppHeader = () => {
    return useHeaderStore((state) => state.component);
};
