import styled, { css } from 'styled-components';
import { FlexBox } from '../../../shared/ui/atoms/flexbox';
import { media } from '../../../shared/mixins/media';
import { Video } from '../../../shared/ui/atoms/video';

export const StyledIcon = styled(FlexBox)`
    width: 32px;
    height: 32px;
    margin-right: 22px;
    background: #EBEBEB;
    border-radius: 50%;
`;

export const StyledNavItem = styled(FlexBox)<{ active?: boolean }>`
    cursor: pointer;
    
    ${({ active, theme }) => active && css`
        ${StyledIcon} {
            background: ${theme.colors.mainNavLinks};
        }
  `}
`;

export const StyledContent = styled.div`
    margin: 30px 0 0;
    color: ${({ theme }) => theme.colors.black2};
    
    &:not(:last-child) {
        margin: 30px 0 40px;
    }

    & img {
      max-width: 100%;
      height: auto;
    }
`;

export const FrameWrapper = styled(Video)`
    padding-bottom: 140%;

    ${media.md(css`
        padding-bottom: 80%;
    `)}
`;
