import { generatePath, NavLink, useNavigate, useParams } from 'react-router-dom';
import { useHowToCategoriesQuery, useHowToCategoryQuery } from '../../../entities/how-to/model/how-to';
import { RouteE } from '../../../shared/config/navigation';
import { ContentBox } from '../../../shared/ui/atoms/content-box';
import { FlexBox } from '../../../shared/ui/atoms/flexbox';
import { Grid } from '../../../shared/ui/atoms/grid';
import { SectionWhiteBox } from '../../../shared/ui/atoms/section-white-box';
import { Spacing } from '../../../shared/ui/atoms/spacing';
import { SvgIcon } from '../../../shared/ui/atoms/svg-icon';
import { IIcon } from '../../../shared/ui/atoms/svg-icon/svg-icon.types';
import { Typography } from '../../../shared/ui/atoms/typography';
import { useSetAppHeader } from '../../../widgets/header/model/store';
import { FrameWrapper, StyledContent, StyledIcon, StyledNavItem } from './styles';
import { useScreenDetect } from '../../../shared/hooks/use-screen-detect';
import React from 'react';
import { Video } from '../../../shared/ui/atoms/video';

const HowToDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const { data: categories } = useHowToCategoriesQuery();
    const { data } = useHowToCategoryQuery(id!);
    const { isMobile } = useScreenDetect();

    useSetAppHeader(
        <FlexBox alignItems='center'>
            <SvgIcon icon='arrow-back' variant='menu' width={15} onClick={() => navigate(RouteE.HOW_TO)} />
            <Spacing ml='25px'>
                {isMobile ? (
                    <Typography size='h_5' color='linksIcons' overflowLines bold uppercase>
                        {data?.name ?? '...'}
                    </Typography>
                ) : (
                    <Typography color='linksIcons' size='h_1' uppercase>
                        <Typography
                            className='pointer'
                            onClick={() => navigate(RouteE.HOW_TO)}
                            color='buttons'
                            size='h_1'
                            uppercase
                        >
                            How To...
                        </Typography>
                        &nbsp;
                        {data?.name}
                    </Typography>
                )}
            </Spacing>
        </FlexBox>
    );

    return (
        <ContentBox>
            <Grid sm={2} columnGap={25} columnsMd='auto 270px'>
                <SectionWhiteBox>
                    {data?.items.map((item) => (
                        <>
                            <FlexBox alignItems='center'>
                                <svg
                                    width='29'
                                    height='29'
                                    viewBox='0 0 29 29'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                    <path fillRule='evenodd' clipRule='evenodd' d='M19.3335 1.51074C19.834 1.51074 20.2397 1.91648 20.2397 2.41699V6.04199C20.2397 6.5425 19.834 6.94824 19.3335 6.94824C18.833 6.94824 18.4272 6.5425 18.4272 6.04199V2.41699C18.4272 1.91648 18.833 1.51074 19.3335 1.51074Z' fill='#E5625C' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M9.6665 1.51074C10.167 1.51074 10.5728 1.91648 10.5728 2.41699V6.04199C10.5728 6.5425 10.167 6.94824 9.6665 6.94824C9.166 6.94824 8.76025 6.5425 8.76025 6.04199V2.41699C8.76025 1.91648 9.166 1.51074 9.6665 1.51074Z' fill='#E5625C' />
                                    <path opacity='0.4' d='M3.625 9.06283C3.625 6.39345 5.78896 4.22949 8.45833 4.22949H20.5417C23.211 4.22949 25.375 6.39345 25.375 9.06283V21.7503C25.375 24.4197 23.211 26.5837 20.5417 26.5837H8.45833C5.78896 26.5837 3.625 24.4197 3.625 21.7503V9.06283Z' fill='#E5625C' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M7.55225 10.875C7.55225 10.3745 7.95799 9.96875 8.4585 9.96875H20.5418C21.0423 9.96875 21.4481 10.3745 21.4481 10.875C21.4481 11.3755 21.0423 11.7812 20.5418 11.7812H8.4585C7.95799 11.7812 7.55225 11.3755 7.55225 10.875Z' fill='#E5625C' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M7.55225 15.709C7.55225 15.2085 7.95799 14.8027 8.4585 14.8027H20.5418C21.0423 14.8027 21.4481 15.2085 21.4481 15.709C21.4481 16.2095 21.0423 16.6152 20.5418 16.6152H8.4585C7.95799 16.6152 7.55225 16.2095 7.55225 15.709Z' fill='#E5625C' />
                                    <path fillRule='evenodd' clipRule='evenodd' d='M7.55225 20.542C7.55225 20.0415 7.95799 19.6357 8.4585 19.6357H14.5002C15.0007 19.6357 15.4064 20.0415 15.4064 20.542C15.4064 21.0425 15.0007 21.4482 14.5002 21.4482H8.4585C7.95799 21.4482 7.55225 21.0425 7.55225 20.542Z' fill='#E5625C' />
                                </svg>
                                <Spacing ml='16px'>
                                    <Typography size='h_4' color='mainNavLinks' bold>
                                        {item.name}
                                    </Typography>
                                </Spacing>
                            </FlexBox>

                            <StyledContent>
                                {item.embedUrl ? (
                                    <FrameWrapper>
                                        <iframe
                                            title={item.name}
                                            src={item.embedUrl}
                                            frameBorder='0'
                                            allowFullScreen
                                            style={{
                                                border: 'none'
                                            }}
                                        />
                                    </FrameWrapper>
                                ) : (
                                    <div dangerouslySetInnerHTML={{ __html: item.text }} />
                                )}
                            </StyledContent>
                        </>
                    ))}
                </SectionWhiteBox>
                <div>
                    <Typography size='h_3' bold>
                        Choose a Topic
                    </Typography>
                    <Spacing mt='15px'>
                        <SectionWhiteBox>
                            <Grid xs={1} rowGap={18}>
                                {categories?.map((item) => (
                                    <NavLink key={item.id} to={generatePath(RouteE.HOW_TO_ITEM, { id: item.id })}>
                                        {({ isActive }) => (
                                            <StyledNavItem
                                                alignItems='center'
                                                active={isActive}
                                            >
                                                <StyledIcon alignItems='center' justifyContent='center'>
                                                    <SvgIcon
                                                        icon={item.icon as IIcon}
                                                        variant='video-tutorials'
                                                        color={isActive ? 'white' : 'icons'}
                                                        width={16}
                                                    />
                                                </StyledIcon>
                                                <Typography
                                                    size='h_5'
                                                    color={isActive ? 'mainNavLinks' : 'black1'}
                                                    className='pointer'
                                                    bold
                                                >
                                                    {item.name}
                                                </Typography>
                                            </StyledNavItem>
                                        )}
                                    </NavLink>
                                ))}
                            </Grid>
                        </SectionWhiteBox>
                    </Spacing>
                </div>

            </Grid>
        </ContentBox>
    );
};

export default HowToDetails;
