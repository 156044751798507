import { FlexBox } from '../../shared/ui/atoms/flexbox';
import { Typography } from '../../shared/ui/atoms/typography';
import { Spacing } from '../../shared/ui/atoms/spacing';
import { StyledListIcon } from './styles';
import { HowToCategory } from '../../shared/api/how-to';
import { FC } from 'react';
import { pluralize } from '../../shared/lib/pluralize';

export const ListItem: FC<{ data: HowToCategory, onClick: () => void }> = ({ onClick, data }) => (
    <FlexBox onClick={onClick}>
        <StyledListIcon>
            <img src={data.icon} />
        </StyledListIcon>
        <Spacing ml='25px'>
            <Typography size='h_2' medium>
                {data.name}
            </Typography>
            <Spacing mt='10px'>
                <Typography size='p' color='black2' medium>
                    {pluralize(data.itemsCount, 'Item')}
                </Typography>
            </Spacing>
        </Spacing>
    </FlexBox>
);
