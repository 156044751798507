import { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import ModalComponent from '../modal-component';
import { ModalRegistryEntityItem } from '../../modal.types';
import { modalRegistry } from '../../providers/modal.registry';
import { ModalContext } from '../../providers/modal.context';
import { useLayoutEffect } from '../../../hooks/use-isomorphic-layout-effect';
import { StyledOverlay, StyledWrapper } from './styles';

export const ModalManagerProvider: FC<PropsWithChildren> = ({ children }) => {
    const [modals, setModals] = useState<ModalRegistryEntityItem[]>([]);

    const isActive = useMemo(() => modals.find(modal => modal.opened), [modals]);
    const activeModals = useMemo(() => modals.filter((m) => m.opened), [modals]);

    const contextValue = useMemo(() => ({
        openedModals: modals
    }), [modals]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const preventDefault = useCallback((e) => {
        if (!e.target.closest?.('[data-element="modals-wrapper"]')) {
            e.preventDefault();
        }
    }, []);

    useLayoutEffect(() => {
        modalRegistry.subscribe(setModals);

        return () => {
            modalRegistry.unsubscribe(setModals);
        };
    }, []);

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';
            document.body.addEventListener('touchstart', preventDefault);
        } else {
            document.body.style.overflow = 'unset';
            document.documentElement.style.overflow = 'unset';
            document.body.removeEventListener('touchstart', preventDefault);
        }
    }, [isActive, preventDefault]);

    return (
        <ModalContext.Provider value={contextValue}>
            {children}
            {!!activeModals.length && ReactDOM.createPortal(
                <StyledWrapper active={!!isActive} data-element='modals-wrapper'>
                    <StyledOverlay />
                    {activeModals?.map((modal, index) => (
                        <ModalComponent
                            modal={modal}
                            key={modal.id}
                            hidden={false}
                        />
                    ))}
                </StyledWrapper>,
                document.body
            )}
        </ModalContext.Provider>
    );
};
