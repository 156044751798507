import { FC, PropsWithChildren } from 'react';
import { Typography } from '../typography';
import { StyledSeparator } from './styles';
import { ISeparator } from './types';

export const Separator: FC<PropsWithChildren<ISeparator>> = ({
    children,
    thickness,
    spacingTop,
    spacingBottom,
    contentSpacing,
    className,
    color
}) => (
    <StyledSeparator
        className={className}
        thickness={thickness}
        spacingTop={spacingTop}
        spacingBottom={spacingBottom}
        contentSpacing={contentSpacing}
        color={color}
    >
        {children && (
            <Typography tag='div' size='h_6' uppercase center>
                {children}
            </Typography>
        )}
    </StyledSeparator>
);
