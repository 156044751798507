import { modalRegistry } from '../../shared/modal/providers/modal.registry';
import { IModalProps } from './types';
import { lazy } from 'react';

const Ui = lazy(() => import('./ui'));

export const ConfirmModal = modalRegistry.register<IModalProps>({
    id: 'Confirm',
    component: Ui
});
