/**
 * Libs
 */
import { forwardRef } from 'react';

/**
 * Types
 */
import { ISpacing } from './spacing.types';

/**
 * Styles
 */
import { StyledSpacing } from './spacing.styles';

export const Spacing = forwardRef<HTMLDivElement, ISpacing>((props : ISpacing, ref) => (
    <StyledSpacing ref={ref} {...props}>
        {props.children}
    </StyledSpacing>
));

Spacing.displayName = 'Spacing';
