export const RouteE = {
    HOME: '/',
    GUEST_MANAGEMENT: '/guest-central',
    GUEST_ADD: '/guest-central/form',
    GUEST_MANAGEMENT_EDIT: '/guest-central/edit/:id',
    STAKEHOLDER_MANAGEMENT: '/stakeholders-central',
    STAKEHOLDER_ADD: '/stakeholders-central/add',
    STAKEHOLDER_EDIT: '/stakeholders-central/edit/:id',
    VAULT: '/vault',

    SIGN_IN: '/sign-in',
    SIGN_UP: '/sign-up',
    AUTH_CALLBACK: '/callback/auth/:driver',
    CONFIRM_EMAIL: '/confirm-email',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password',

    EVENT_CREATE: '/event/create',
    EVENT_DETAILS: '/event/:id',
    EVENT_DETAILS_VENUE_SELECTIONS: '/event/:id/venue/selections',
    EVENT_DETAILS_VENUE_SELECTIONS_IDEAS: '/event/:id/venue/selections/ideas',
    EVENT_DETAILS_VENUE_SELECTIONS_LISTS: '/event/:id/venue/selections/lists',
    EVENT_DETAILS_VENUE_SELECTIONS_VENDORS: '/event/:id/venue/selections/vendors',
    EVENT_DETAILS_VENUE_SELECTIONS_QUOTES: '/event/:id/venue/selections/quotes',
    EVENT_DETAILS_VENUE_POLLING: '/event/:id/venue/selections/polling',
    EVENT_DETAILS_VENUE_SEATING_CHART: '/event/:id/venue/seating-chart',
    EVENT_DETAILS_VENUE_FLOOR_PLAN: '/event/:id/venue/floor-plan',

    EVENT_DETAILS_DECOR: '/event/:id/decor',
    EVENT_DETAILS_DECOR_TABLE_SETTINGS: '/event/:id/decor/table-settings',
    EVENT_DETAILS_DECOR_LIGHTING_SIGNAGE: '/event/:id/decor/lighting-signage',
    EVENT_DETAILS_DECOR_PRINTED_GOODS: '/event/:id/decor/printed-goods',
    EVENT_DETAILS_DECOR_FAVORS: '/event/:id/decor/favors',
    EVENT_DETAILS_DECOR_RENTALS: '/event/:id/decor/rentals',
    EVENT_DETAILS_DECOR_OVERALL_DESIGN: '/event/:id/decor/overall-design',

    EVENT_DETAILS_FOOD_BEVERAGE: '/event/:id/food-beverage',
    EVENT_DETAILS_FOOD_BEVERAGE_MENU_CATERERS:
        '/event/:id/food-beverage/menu-caterers',
    EVENT_DETAILS_FOOD_BEVERAGE_POTLUCK: '/event/:id/food-beverage/potluck',
    EVENT_DETAILS_FOOD_BEVERAGE_FOOD_TRUCKS:
        '/event/:id/food-beverage/food-trucks',
    EVENT_DETAILS_FOOD_BEVERAGE_FOOD_RENTALS:
        '/event/:id/food-beverage/food-rentals',

    EVENT_DETAILS_LOGISTICS_OVERVIEW: '/event/:id/logistics-overview',
    EVENT_DETAILS_LOGISTICS_OVERVIEW_SUMMARY:
        '/event/:id/logistics-overview/overview',
    EVENT_DETAILS_LOGISTICS_OVERVIEW_CALENDAR:
        '/event/:id/logistics-overview/calendar',
    EVENT_DETAILS_LOGISTICS_OVERVIEW_SCHEDULE:
        '/event/:id/logistics-overview/schedule',
    EVENT_DETAILS_LOGISTICS_OVERVIEW_CONTACTS:
        '/event/:id/logistics-overview/contacts',
    EVENT_DETAILS_LOGISTICS_OVERVIEW_EXPENSES:
        '/event/:id/logistics-overview/expenses',

    EVENT_DETAILS_LOGISTICS_TRAVEL: '/event/:id/logistics-travel',
    EVENT_DETAILS_LOGISTICS_TRAVEL_IDEAS: '/event/:id/logistics-travel/ideas',
    EVENT_DETAILS_LOGISTICS_TRAVEL_LISTS: '/event/:id/logistics-travel/lists',

    EVENT_DETAILS_ENTERTAINMENT: '/event/:id/entertainment',
    EVENT_DETAILS_ENTERTAINMENT_MUSIC: '/event/:id/entertainment/music',
    EVENT_DETAILS_ENTERTAINMENT_PHOTO_VIDEO:
        '/event/:id/entertainment/photos-video',
    EVENT_DETAILS_ENTERTAINMENT_PERFORMERS:
        '/event/:id/entertainment/performers',
    EVENT_DETAILS_ENTERTAINMENT_PARTY_RENTALS:
        '/event/:id/entertainment/party-rentals',

    EVENT_DETAILS_COMMUNICATION_SAVE_THE_DATE:
        '/event/:id/communication/save-the-date',
    EVENT_DETAILS_COMMUNICATION_SAVE_THE_DATE_LIST:
        '/event/:id/communication/save-the-date/guestlist',
    EVENT_DETAILS_COMMUNICATION_INVITATIONS:
        '/event/:id/communication/invitations',
    EVENT_DETAILS_COMMUNICATION_INVITATIONS_LIST:
        '/event/:id/communication/invitations/guestlist',
    EVENT_DETAILS_COMMUNICATION_THANK_YOU_NOTES:
        '/event/:id/communication/thank-you-notes',
    EVENT_DETAILS_COMMUNICATION_THANK_YOU_NOTES_LIST:
        '/event/:id/communication/thank-you-notes/guestlist',

    EVENT_DETAILS_FINAL_GO_LIVE: '/event/:id/final-go-live',

    PROFILE: '/profile',
    PROFILE_MAIN: '/profile/main',
    PROFILE_SUBSCRIPTION: '/profile/subscription',
    PROFILE_BILLING_HISTORY: '/profile/billing-history',
    PROFILE_SECURITY: '/profile/security',
    PROFILE_NOTIFICATIONS: '/profile/notifications',
    PAYMENTS: '/profile/subscription',
    VIDEO_TUTORIALS: '/video-tutorials',
    HOW_TO: '/how-to',
    HOW_TO_ITEM: '/how-to/:id',
    FAQ: '/faq',

    TERMS_AND_CONDITIONS: 'https://swankey.com/access-policy'
};

export const PUBLIC_ROUTES = [
    RouteE.SIGN_IN,
    RouteE.SIGN_UP,
    RouteE.AUTH_CALLBACK,
    RouteE.CONFIRM_EMAIL,
    RouteE.FORGOT_PASSWORD,
    RouteE.RESET_PASSWORD
];
